import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Grid, Pagination, Autoplay } from "swiper";
import "swiper/modules/grid/grid.min.css";

import locationDropper from "./assets/locationDropper.svg";
import "./styles.css";

import { axiosUser } from "../../axiosInstance";
import { useContext } from "react";
import { LocationContext } from "../../Context";
import ComponentLoading from "../../components/componentLoading";

SwiperCore.use([Grid, Pagination, Autoplay]);

function ShopsNearby() {
  const [shops, setShops] = useState([]);
  const { locationCoords } = useContext(LocationContext);
  const [componentLoading, setComponentLoading] = useState(false);

  useEffect(() => {
    if (locationCoords) {
      setComponentLoading(true);
      axiosUser
        .get("/homepage/shops/nearby")
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setShops(res.data.shops);
          }
          setComponentLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setComponentLoading(false);
        });
    }
  }, [locationCoords]);

  if (shops[0]) {
    return (
      <Swiper
        slidesPerView={3}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        grid={{
          rows: 2,
          fill: "row",
        }}
        breakpoints={{
          0: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 7,
          },
          760: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
          },
        }}
        spaceBetween={15}
        className="shopsNearbySwiper"
      >
        {shops.map((shop) => {
          return (
            <SwiperSlide key={shop.shop_uid}>
              <Link
                to={`/shop/${shop.shop_uid}`}
                className="shopNearbyCard textDecorNone flex"
                style={{
                  backgroundImage: `url(${
                    process.env.NODE_ENV === "production"
                      ? "https://www.locaro.in/api"
                      : "http://localhost:5000/api"
                  }/images/${shop.shop_image})`,
                }}
              >
                <div className="shopNearbyCardOverlayDiv flexColumn">
                  <h2 className="text1Line">{shop.shop_name}</h2>
                  <div className="shopNearbyCardRatingDiv flex flexAlignCenter">
                    <div
                      className="shopNearbyCardRating"
                      style={{
                        backgroundColor:
                          shop.rating === null
                            ? "gray"
                            : shop.rating < 3
                            ? "#b90504"
                            : shop.rating < 4
                            ? "orange"
                            : "green",
                      }}
                    >
                      {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "}{" "}
                      &#9733;
                    </div>
                    <h3>
                      {shop.rating_count > 0 ? shop.rating_count : "No"} Review
                      {shop.rating_count > 1 || shop.rating_count === "0"
                        ? "s"
                        : null}
                    </h3>
                  </div>
                  <div className="shopNearbyCardDistanceDiv flex flexAlignCenter">
                    <img src={locationDropper} alt="" />
                    <h5>{Math.round(shop.distance)}m Away</h5>
                  </div>
                  <h4 className="text2Lines">{shop.shop_address}</h4>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  } else {
    return (
      <div className="homePageNoShopsNearbyDiv flex flexJustifyAndAlignCenter">
        {componentLoading ? (
          <ComponentLoading minHeight="100%" />
        ) : (
          "Sorry, No shops near you right now."
        )}
      </div>
    );
  }
}

export default ShopsNearby;

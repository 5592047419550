import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { axiosUser } from "../../axiosInstance";
import { LoadingContext, UserContext } from "../../Context";

import "./styles.css";

function Reviews({ setReviewsOverlay }) {
  const history = useHistory();
  const [reviewList, setReviewList] = useState([]);
  const [userReview, setUserReview] = useState();
  const [maxPages, setMaxPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();
  const { setLoading } = useContext(LoadingContext);
  const { userInfo } = useContext(UserContext);

  const getReviews = () => {
    axiosUser
      .get(`/search/product/${id}/reviews`, { params: { page: currentPage } })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setReviewList(res.data.reviews);
          axiosUser
            .get(`/user/products/review`, { params: { id } })
            .then((res) => {
              console.log(res.data);
              if (res.data.status === "success") {
                setUserReview(res.data.userReview);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReviews();
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      getReviews();
    }
  }, [currentPage]);

  useEffect(() => {
    if (userReview && reviewList[0]) {
      setReviewList(
        reviewList.filter(
          (element) =>
            element.product_review_uid !== userReview.product_review_uid
        )
      );
    }
  }, [userReview]);

  useEffect(() => {
    if (reviewList.length % 20 > 0) {
      setMaxPage(Math.floor(reviewList.length / 20) + 1);
    } else {
      setMaxPage(Math.floor(reviewList.length / 20));
    }
  }, [reviewList]);

  const deleteHandler = () => {
    setLoading(true);
    axiosUser
      .delete(`/user/products/review`, { params: { id } })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          axiosUser
            .get(`/user/products/review`, { params: { id } })
            .then((res) => {
              setLoading(false);
              console.log(res.data);
              if (res.data.status === "success") {
                setUserReview(res.data.userReview);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="productPageReviewContainer productMiddleDivSections flexColumn">
      {userReview && userReview !== null ? (
        <>
          <div className="flex flexAlignCenter">
            <h1 className="productMiddleDivSectionHeader">Your Review</h1>
            <div
              className="redButton flex flexJustifyAndAlignCenter"
              style={{
                height: "30px",
                padding: "0 10px",
                margin: "7px 15px 0 auto",
              }}
              onClick={deleteHandler}
            >
              Delete
            </div>
          </div>
          <div className="productReviewCard flexColumn">
            <div className="flex">
              <div
                className="productMiddleDivRating"
                style={{
                  backgroundColor:
                    userReview.review_rating <= 2
                      ? "#b90504"
                      : userReview.review_rating <= 3
                      ? "orange"
                      : userReview.review_rating < 4
                      ? "yellow"
                      : "green",
                }}
              >
                {userReview.review_rating}.0 &#9733;
              </div>
              {userReview.review_text.length > 0 ? (
                <p>{userReview.review_text}</p>
              ) : (
                <h1 style={{ marginTop: "5px" }}>
                  ~ {userReview.user_full_name} <span>(Certified Buyer)</span>
                </h1>
              )}
            </div>
            {userReview.review_text.length > 0 ? (
              <h1>
                ~ {userReview.user_full_name} <span>(Certified Buyer)</span>
              </h1>
            ) : null}
          </div>
        </>
      ) : (
        <div
          className="productReviewCard flex flexJustifyAndAlignCenter"
          style={{ height: "80px" }}
        >
          <div
            className="redButton flex flexJustifyAndAlignCenter"
            onClick={() => {
              if (userInfo.gotUser) {
                setReviewsOverlay(true);
              } else {
                history.push("/user/login");
              }
            }}
          >
            Write A Review
          </div>
        </div>
      )}
      {maxPages > 0 ? (
        <>
          <h1 className="productMiddleDivSectionHeader">Reviews</h1>
          {reviewList.map((element) => {
            return (
              <div
                className="productReviewCard flexColumn"
                key={element.product_review_uid}
              >
                <div className="flex">
                  <div
                    className="productMiddleDivRating"
                    style={{
                      backgroundColor:
                        element.review_rating < 3
                          ? "#b90504"
                          : element.review_rating < 4
                          ? "orange"
                          : "green",
                    }}
                  >
                    {element.review_rating}.0 &#9733;
                  </div>
                  <p>{element.review_text}</p>
                </div>
                <h1>
                  ~ {element.user_full_name} <span>(Certified Buyer)</span>
                </h1>
              </div>
            );
          })}
          {currentPage < maxPages ? (
            <div
              style={{
                textAlign: "center",
                color: "dimgray",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
            >
              Show more
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default Reviews;

import React, { useContext, useEffect } from "react";

import { axiosUser } from "../../axiosInstance";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "./styles.css";

import "swiper/modules/navigation/navigation.min.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { LocationContext } from "../../Context";

SwiperCore.use([Navigation, Autoplay]);

function HorizontalSwiper() {
  const [products, setProducts] = useState([]);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const { locationCoords } = useContext(LocationContext);

  useEffect(() => {
    axiosUser
      .get("/homepage/bestsellers")
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setProducts(res.data.bestsellers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [locationCoords]);

  return (
    <>
      {products[4] ? (
        <div className="componentContainer">
          <h1>Best Sellers Near You</h1>
          <Swiper
            slidesPerView={5}
            spaceBetween={7}
            autoplay={{
              delay: 3500,
              disableOnInteraction: true,
            }}
            navigation={window.outerWidth > 780}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              760: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
            }}
            className="horizontalSwiper"
          >
            {products.map((product) => {
              return (
                <SwiperSlide key={product.catalog_uid}>
                  <Link
                    to={`/${product.is_grocery ? "grocery" : "product"}/${
                      product.catalog_uid
                    }${
                      product.is_grocery ? `?shop=${product.product_uid}` : ""
                    }`}
                    className="homePageProductCards flexColumn card-boxshadow textDecorNone"
                  >
                    <div className="homePageProductImageDiv flex flexJustifyAndAlignCenter">
                      <img
                        src={`${
                          process.env.NODE_ENV === "production"
                            ? "https://www.locaro.in/api"
                            : "http://localhost:5000/api"
                        }/images/products/${
                          Array.isArray(product.product_images)
                            ? product.product_images[0]
                            : "null"
                        }`}
                        alt=""
                      />
                    </div>
                    <h2 className="text2Lines">{product.product_name}</h2>
                    <div className="homePageProductPriceDiv flexColumn flexAlignCenter">
                      <span>{formatter.format(product.product_mrp)}</span>
                      <p>{formatter.format(product.product_price)}</p>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : null}
    </>
  );
}

export default HorizontalSwiper;

import React, { useState, useEffect, Children } from "react";

import ComponentLoading from "../../components/componentLoading";
import LeftDiv from "./LeftDiv";
import Reviews from "./Reviews";
import RightDiv from "./RightDiv";

function MiddleDiv(props) {
  const {
    catalogDetails,
    shop,
    productMainDivLoading,
    children,
    productListings,
    shopDetailsDivLoading,
    setReviewsOverlay,
  } = props;

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  if (window.outerWidth <= 480) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div
                className="productMiddleDivRating"
                style={{
                  backgroundColor:
                    catalogDetails.rating === null
                      ? "gray"
                      : catalogDetails.rating < 3
                      ? "#b90504"
                      : catalogDetails.rating < 4
                      ? "orange"
                      : "green",
                }}
              >
                {catalogDetails.rating > 0
                  ? catalogDetails.rating.slice(0, 3)
                  : " -- "}{" "}
                &#9733;
              </div>
              <h1>
                {catalogDetails.rating_count > 0
                  ? catalogDetails.rating_count
                  : "No"}{" "}
                Review
                {catalogDetails.rating_count > "1" ||
                catalogDetails.rating_count === "0"
                  ? "s"
                  : null}
              </h1>
            </div>
          )}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ marginLeft: "20px" }}>
              Grocery
            </div>
          ) : null}
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        <LeftDiv catalogDetails={catalogDetails} />
        <RightDiv
          catalogDetails={catalogDetails}
          shop={shop}
          productListings={productListings}
          shopDetailsDivLoading={shopDetailsDivLoading}
        />
        <div
          className="productMiddleDivSections"
          style={{ padding: "0px 0px" }}
        >
          {children}
        </div>
        {catalogDetails.product_description ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Description</h1>
            <h2 className="productMiddleDivDescription">
              {catalogDetails.product_description
                ? catalogDetails.product_description
                : null}
            </h2>
          </div>
        ) : null}
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
        <Reviews setReviewsOverlay={setReviewsOverlay} />
      </div>
    );
  } else if (window.outerWidth > 480 && window.outerWidth <= 1200) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div
                className="productMiddleDivRating"
                style={{
                  backgroundColor:
                    catalogDetails.rating === null
                      ? "gray"
                      : catalogDetails.rating < 3
                      ? "#b90504"
                      : catalogDetails.rating < 4
                      ? "orange"
                      : "green",
                }}
              >
                {catalogDetails.rating > 0
                  ? catalogDetails.rating.slice(0, 3)
                  : " -- "}{" "}
                &#9733;
              </div>
              <h1>
                {catalogDetails.rating_count > 0
                  ? catalogDetails.rating_count
                  : "No"}{" "}
                Review
                {catalogDetails.rating_count > 1 ||
                catalogDetails.rating_count === "0"
                  ? "s"
                  : null}
              </h1>
            </div>
          )}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ marginLeft: "20px" }}>
              Grocery
            </div>
          ) : null}
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        <LeftDiv catalogDetails={catalogDetails} />
        <div
          className="productMiddleDivSections"
          style={{ padding: "0px 0px" }}
        >
          {children}
        </div>
        {catalogDetails.product_description ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Description</h1>
            <h2 className="productMiddleDivDescription">
              {catalogDetails.product_description
                ? catalogDetails.product_description
                : null}
            </h2>
          </div>
        ) : null}
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div
            className="productMiddleDivSections"
            style={{ paddingBottom: "5px" }}
          >
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
        <Reviews setReviewsOverlay={setReviewsOverlay} />
      </div>
    );
  } else if (window.outerWidth > 1200) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div
                className="productMiddleDivRating"
                style={{
                  backgroundColor:
                    catalogDetails.rating === null
                      ? "gray"
                      : catalogDetails.rating < 3
                      ? "#b90504"
                      : catalogDetails.rating < 4
                      ? "orange"
                      : "green",
                }}
              >
                {catalogDetails.rating > 0
                  ? catalogDetails.rating.slice(0, 3)
                  : " -- "}{" "}
                &#9733;
              </div>
              <h1>
                {catalogDetails.rating_count > 0
                  ? catalogDetails.rating_count
                  : "No"}{" "}
                Review
                {catalogDetails.rating_count > 1 ||
                catalogDetails.rating_count === "0"
                  ? "s"
                  : null}
              </h1>
            </div>
          )}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ marginLeft: "20px" }}>
              Grocery
            </div>
          ) : null}
          <div className="productMiddleDivSectionPriceDiv">
            <h3>
              MRP:{" "}
              <span>
                {catalogDetails.product_mrp
                  ? formatter.format(catalogDetails.product_mrp)
                  : null}
              </span>
            </h3>
            <h2 className="productMiddleDivSectionPrice">
              Shop Price:{" "}
              <span>
                {shop
                  ? formatter.format(shop.product_price)
                  : "Unavailable in your location"}
              </span>
            </h2>
            <h4>
              Save:{" "}
              <span>
                {shop
                  ? formatter.format(
                      catalogDetails.product_mrp - shop.product_price
                    )
                  : null}
              </span>
              <span style={{ fontSize: "13px" }}>
                {shop
                  ? ` (${Math.floor(
                      ((catalogDetails.product_mrp - shop.product_price) /
                        catalogDetails.product_mrp) *
                        100
                    )}% OFF)`
                  : null}
              </span>
            </h4>
          </div>
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        <div
          className="productMiddleDivSections"
          style={{ padding: "0px 0px" }}
        >
          {children}
        </div>
        {catalogDetails.product_description ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Description</h1>
            <h2 className="productMiddleDivDescription">
              {catalogDetails.product_description
                ? catalogDetails.product_description
                : null}
            </h2>
          </div>
        ) : null}
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div
            className="productMiddleDivSections"
            style={{ paddingBottom: "5px" }}
          >
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
        <Reviews setReviewsOverlay={setReviewsOverlay} />
      </div>
    );
  }
}

export default MiddleDiv;

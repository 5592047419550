import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import ComponentLoading from "../../components/componentLoading";
import queryString from "query-string";

import SmallNavBar from "../../components/smallNavBar";
import Footer from "../../components/footer/index";
import {
  SearchFilterBox,
  MobileFilterPopUp,
} from "../../components/searchFilterBox/index";
import FilterBar from "./FilterBar";
import SearchResults from "./SearchResults";
import { axiosUser } from "../../axiosInstance";
import { LocationContext } from "../../Context";

function ProductSearchPage() {
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const { locationCoords } = useContext(LocationContext);
  const [maxPage, setMaxPage] = useState();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const radius = query.get("radius");
  const brandQuery = queryString.parse(location.search).brand;
  const categoryQuery = queryString.parse(location.search).category;
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const [productLoading, setProductLoading] = useState(true);
  const [queryStrings, setQueryStrings] = useState();

  const getProducts = (page) => {
    setProductLoading(true);
    axiosUser
      .get("search/products", {
        params: {
          search,
          radius,
          page,
          brand: brandQuery,
          category: categoryQuery,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setProducts(res.data.products);
          setProductLoading(false);
          if (res.data.count % 20 > 0) {
            setMaxPage(Math.floor(res.data.count / 20) + 1);
          } else {
            setMaxPage(Math.floor(res.data.count / 20));
          }
        } else {
          setProductLoading(false);
        }
      })
      .catch((err) => {
        setProductLoading(false);
        console.log(err.message);
      });
  };

  useEffect(() => {
    setQueryStrings(queryString.parse(location.search));
  }, []);

  useEffect(() => {
    if (locationCoords) {
      getProducts(1);
    }
  }, [location, locationCoords]);

  useEffect(() => {
    if (queryStrings) {
      const stringified = queryString.stringify(queryStrings);
      history.push("/search/products?" + stringified);
    }
  }, [queryStrings]);

  if (!products[0] && !productLoading) {
    if (window.outerWidth <= 780) {
      return (
        <>
          <SmallNavBar />
          <div
            id="productSearchPageMainContainer"
            className="flexColumn flexJustifyCenter"
            style={{ minHeight: "calc(100vh - 119px)" }}
          >
            <FilterBar
              setQueryStrings={setQueryStrings}
              queryStrings={queryStrings}
            />
            <div
              id="productsNoResultsDiv"
              className=" flexColumn flexJustifyAndAlignCenter"
              style={{ width: "100%", marginTop: "40px" }}
            >
              <h1>Sorry, no results found.</h1>
              <p>
                Check for spelling errors or try searching for something else
              </p>
              <Link
                to="/"
                className="redButton flex flexJustifyAndAlignCenter textDecorNone"
              >
                Check Product Categories
              </Link>
            </div>
            <SearchFilterBox setMobilePopUp={setMobilePopUp} />
          </div>
          <MobileFilterPopUp
            setMobilePopUp={setMobilePopUp}
            mobilePopUp={mobilePopUp}
          />
        </>
      );
    } else {
      return (
        <>
          <SmallNavBar />
          <div
            id="productSearchPageMainContainer"
            className="flex flexJustifyCenter"
          >
            <SearchFilterBox />
            <div
              id="productsNoResultsDiv"
              className=" flexColumn flexJustifyAndAlignCenter"
            >
              <h1>Sorry, no results found.</h1>
              <p>
                Check for spelling errors or try searching for something else
              </p>
              <Link
                to="/"
                className="redButton flex flexJustifyAndAlignCenter textDecorNone"
              >
                Check Product Categories
              </Link>
            </div>
          </div>
        </>
      );
    }
  } else {
    if (window.outerWidth <= 780) {
      return (
        <>
          <SmallNavBar />
          <div
            id="productSearchPageMainContainer"
            className="flexColumn flexJustifyCenter"
          >
            <Link
              className="flashCardSuccess textDecorNone flex flexJustifyAndAlignCenter"
              style={{
                color: "white",
                backgroundColor: "green",
                border: "none",
                fontWeight: "600",
                fontSize: "16px",
                height: "60px",
                boxSizing: "border-box",
                borderRadius: "10px",
                width: "calc(100% - 10px)",
                margin: "0px 5px",
              }}
              to={`/search/grocery?search=${search}`}
            >
              Want Groceries? Click here
            </Link>
            <FilterBar
              setQueryStrings={setQueryStrings}
              queryStrings={queryStrings}
            />
            <SearchResults
              products={products}
              getProducts={getProducts}
              setProducts={setProducts}
              maxPage={maxPage}
            />
            <SearchFilterBox setMobilePopUp={setMobilePopUp} />
          </div>
          <MobileFilterPopUp
            setMobilePopUp={setMobilePopUp}
            mobilePopUp={mobilePopUp}
          />
          <Footer />
        </>
      );
    } else {
      return (
        <>
          <SmallNavBar />
          <div
            id="productSearchPageMainContainer"
            className="flex flexJustifyCenter"
          >
            <SearchFilterBox
              queryStrings={queryStrings}
              setQueryStrings={setQueryStrings}
            />
            <div className="flexColumn" id="rightContainer">
              <FilterBar
                setQueryStrings={setQueryStrings}
                queryStrings={queryStrings}
              />
              <SearchResults
                products={products}
                getProducts={getProducts}
                setProducts={setProducts}
                maxPage={maxPage}
              />
            </div>
          </div>
          <Footer />
          {productLoading ? <ComponentLoading position="fixed" /> : null}
        </>
      );
    }
  }
}

export default ProductSearchPage;

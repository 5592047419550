import React, { useContext, useState } from "react";
import { axiosUser } from "../../axiosInstance";
import { LoadingContext } from "../../Context";

import "./styles.css";

function ProductAddReview({ catalogDetails, setReviewsOverlay }) {
  const [stars, setStars] = useState(3);
  const { setLoading } = useContext(LoadingContext);
  const [error, setError] = useState(false);

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const reviewBody = {
      review_rating: stars,
      review_text: e.target.reviewMessage.value,
      catalog_uid: catalogDetails.catalog_uid,
    };
    axiosUser
      .post("/user/products/review", reviewBody)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setReviewsOverlay(false);
          window.location.reload();
          setLoading(false);
        } else if (res.data.message === "Letter Limit Exceeded (1500)") {
          setError("Text limit reached (1500)");
          setLoading(false);
        } else if (
          res.data.message ===
          'null value in column "shop_uid" violates not-null constraint'
        ) {
          setLoading(false);
          setError(
            "You can only leave a review if you have bought this product"
          );
        } else {
          setLoading(false);
          setError("You can only post 1 review per product");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
        setLoading(false);
      });
  };
  return (
    <div className="overlayDiv" style={{ touchAction: "none" }}>
      {error ? (
        <div
          className="flashCardError"
          style={{
            marginTop: "80px",
            width: window.outerWidth < 480 ? "95%" : "400px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        >
          {error}
        </div>
      ) : null}
      <div className="addReviewsContainer flexColumn">
        <div
          className="closeButton"
          style={{ color: "black" }}
          onClick={() => {
            setReviewsOverlay(false);
          }}
        >
          &times;
        </div>
        <div className="addReviewsProductInfo flex flexAlignCenter">
          <div className="addReviewsProductImageDiv flex flexJustifyAndAlignCenter">
            <img
              src="https://rukminim1.flixcart.com/image/312/312/ku04o7k0/mobile/p/8/u/9i-sport-mzb0a5iin-redmi-original-imag785qegs5ghyy.jpeg?q=70"
              alt=""
            />
          </div>
          <h1 className="text2Lines">{catalogDetails.product_name}</h1>
        </div>
        <form
          className="addReviewsForm flexColumn flexAlignCenter"
          onSubmit={submitHandler}
        >
          <div
            className="flex flexJustifyAndAlignCenter"
            style={{ width: "100%", gap: "10px" }}
          >
            <input
              type="range"
              name="stars"
              defaultValue="3"
              min="1"
              max="5"
              step="1"
              onChange={(e) => {
                setStars(e.target.value);
              }}
            ></input>
            <div className="addReviewsSetReviewDiv">{stars} &#9733;</div>
          </div>
          <textarea name="reviewMessage" placeholder="Review Text"></textarea>
          <button className="redButton" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ProductAddReview;

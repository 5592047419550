import React from "react";

import "./styles.css";

function FilterBar({ setQueryStrings, queryStrings }) {
  return (
    <div id="productSearchPageFilterBar">
      <form className="flex" style={{ width: "100%" }}>
        <div style={{ marginLeft: "auto" }}>
          <label
            htmlFor="searchPageRadiusSelect"
            className="redBackgroundSelectLabel"
          >
            Search Radius :{" "}
          </label>
          <select
            name="radius"
            id="searchPageRadiusSelect"
            className="redBackgroundSelect"
            onChange={(e) => {
              setQueryStrings({ ...queryStrings, radius: e.target.value });
            }}
            defaultValue={
              queryStrings && queryStrings.radius ? queryStrings.radius : "10"
            }
          >
            <option value="2">2 KM</option>
            <option value="4">4 KM</option>
            <option value="6">6 KM</option>
            <option value="8">8 KM</option>
            <option value="10">10 KM</option>
          </select>
        </div>
        {/* <div style={{ marginLeft: "auto" }}>
          <label
            htmlFor="searchPageSortBySelect"
            className="redBackgroundSelectLabel"
          >
            Sort By :{" "}
          </label>
          <select
            name="radius"
            id="searchPageSortBySelect"
            className="redBackgroundSelect"
            onChange={(e) => {
              setQueryStrings({ ...queryStrings, sort: e.target.value });
            }}
            defaultValue={
              queryStrings && queryStrings.sort
                ? queryStrings.sort
                : "Relevance"
            }
          >
            <option value="Relevance">Relevance</option>
            <option value="Distance">Distance</option>
          </select>
        </div> */}
      </form>
    </div>
  );
}

export default FilterBar;

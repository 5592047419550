import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import SmallNavBar from "../../components/smallNavBar";

function ErrorPage() {
  return (
    <>
      <SmallNavBar />
      <div id="errorPageBody" className="flex flexJustifyAndAlignCenter">
        <div
          id="errorPageContainer"
          className="flexColumn flexJustifyAndAlignCenter"
        >
          <h1>404</h1>
          <h2>Page Not Found!</h2>
          <h3>Sorry, the page you're looking for doesn't exist</h3>
          <Link
            to="/"
            className="redButton textDecorNone flex flexJustifyAndAlignCenter"
            id="errorPageHomeButton"
          >
            Go To Home
          </Link>
        </div>
      </div>
    </>
  );
}

export default ErrorPage;

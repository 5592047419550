import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import OTP from "./OTP";

import "./styles.css";
import { axiosUser } from "../../axiosInstance";

function RegisterPage() {
  const history = useHistory();
  // const [showOTP, setShowOTP] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    const registerData = {
      userFullName: e.target.userFullName.value,
      userEmail: e.target.userEmail.value,
      userPhoneNumber: e.target.userPhoneNumber.value,
      userLocation: { longitude: null, latitude: null },
      userAddress: null,
      userPincode: null,
      password: e.target.userPassword.value,
      confirmPassword: e.target.userConfirmPassword.value,
    };
    axiosUser
      .post("/auth/user/register", registerData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <React.Fragment>
      <div id="registerBodyTop">
        <h1>Locaro</h1>
      </div>
      <div id="registerBodyBottom"></div>
      <div id="registerContainerParentDiv">
        <div id="registerContainer">
          <h1>Register</h1>
          <form id="registerForm" onSubmit={submitHandler}>
            <div className="animatedInputDivs">
              <input
                type="text"
                name="userPhoneNumber"
                id="userPhoneNumberRegisterInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userPhoneNumberRegisterInput"
                className="animatedInputLabels"
              >
                Mobile Number
              </label>
            </div>
            <div className="animatedInputDivs">
              <input
                type="text"
                name="userFullName"
                id="userFullNameRegisterInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userFullNameRegisterInput"
                className="animatedInputLabels"
              >
                Full Name
              </label>
            </div>
            <div className="animatedInputDivs" style={{ marginBottom: "30px" }}>
              <input
                type="text"
                name="userEmail"
                id="userEmailRegisterInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userEmailRegisterInput"
                className="animatedInputLabels"
              >
                Email
              </label>
            </div>
            <div className="animatedInputDivs">
              <input
                type="password"
                name="userPassword"
                id="userPasswordRegisterInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userPasswordRegisterInput"
                className="animatedInputLabels"
              >
                Password
              </label>
            </div>
            <div className="animatedInputDivs">
              <input
                type="password"
                name="userConfirmPassword"
                id="userConfirmPasswordRegisterInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userConfirmPasswordRegisterInput"
                className="animatedInputLabels"
              >
                Confirm Password
              </label>
            </div>
            <button
              className="redButton"
              type="submit"
              style={{ margin: "20px 0px" }}
              // onClick={() => {
              //   setShowOTP(true);
              // }}
            >
              Continue
            </button>
          </form>
          <Link to="/user/login" id="goToLoginAndRegister">
            Login
          </Link>
        </div>
      </div>
      {/* {showOTP ? <OTP setShowOTP={setShowOTP} /> : null} */}
    </React.Fragment>
  );
}

export default RegisterPage;

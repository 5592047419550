import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";
import Reviews from "./Reviews";

import ComponentLoading from "../../components/componentLoading";
import "./styles.css";

function RightDiv({ setReviewOverlay }) {
  const history = useHistory();
  const { id } = useParams();
  const [productsLoading, setProductsLoading] = useState(false);
  const [shopProductInventory, setShopProductInventory] = useState([]);
  const [shopGroceryInventory, setShopGroceryInventory] = useState([]);
  const [type, setType] = useState("products");
  const [showAll, setShowAll] = useState(false);

  const getShopInventory = () => {
    setProductsLoading(true);
    axiosUser
      .get(`/public/business/${id}/inventory`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setShopProductInventory(res.data.products);
          setShopGroceryInventory(res.data.groceries);
        }
        setProductsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProductsLoading(false);
      });
  };

  const searchHandler = (searchInput) => {
    axiosUser
      .get(`/public/business/${id}/inventory?query=${searchInput}`)
      .then((res) => {
        console.log(res.data);
        setShopProductInventory(res.data.products);
        setShopGroceryInventory(res.data.groceries);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(() => {
    getShopInventory();
  }, []);

  useEffect(() => {
    if (type === "products") {
      document
        .getElementById("shopDetailsProductTab")
        .classList.add("shopDetailsTabActive");

      document
        .getElementById("shopDetailsGroceryTab")
        .classList.remove("shopDetailsTabActive");
    } else {
      document
        .getElementById("shopDetailsProductTab")
        .classList.remove("shopDetailsTabActive");

      document
        .getElementById("shopDetailsGroceryTab")
        .classList.add("shopDetailsTabActive");
    }
  }, [type]);

  if (window.outerWidth <= 780) {
    return (
      <div id="rightDivContainer">
        <div id="shopInventoryTools" className="flexColumn flexAlignCenter">
          <div id="shopDetailsProductTypeDiv" className="flex flexAlignCenter">
            <div
              id="shopDetailsProductTab"
              className="flex flexJustifyAndAlignCenter"
              onClick={() => {
                setType("products");
              }}
            >
              <h1>Products</h1>
            </div>
            <div
              id="shopDetailsGroceryTab"
              className="flex flexJustifyAndAlignCenter"
              onClick={() => {
                setType("groceries");
              }}
            >
              <h1>Groceries</h1>
            </div>
          </div>
          <form id="shopInventoryToolsForm" className="flex flexJustifyRight">
            <input
              type="text"
              className="shopDetailsInventorySearch"
              placeholder="Search Inventory"
              onChange={(e) => {
                searchHandler(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="shopDetailsInventoryCardParentDiv">
          {type === "products" ? (
            shopProductInventory[0] ? (
              shopProductInventory
                .filter((element) => {
                  if (showAll) {
                    return true;
                  } else {
                    return shopProductInventory.indexOf(element) < 10;
                  }
                })
                .map((product) => {
                  return (
                    <div
                      className="shopDetailsInventoryCards card-boxshadow flex"
                      key={product.product_uid}
                      title={product.product_name}
                    >
                      <div
                        id="shopDetailsInventoryImage"
                        className="flex flexJustifyAndAlignCenter"
                      >
                        <img
                          src={`${
                            process.env.NODE_ENV === "production"
                              ? "https://www.locaro.in/api"
                              : "http://localhost:5000/api"
                          }/images/products/${
                            Array.isArray(product.product_images)
                              ? product.product_images[0]
                              : "null"
                          }`}
                          alt="image"
                        />
                      </div>
                      <div
                        id="shopDetailsInventoryTextDiv"
                        className="flexColumn"
                        onClick={() => {
                          history.push(
                            `/${type === "products" ? "product" : "grocery"}/${
                              product.catalog_uid
                            }?shop=${
                              type === "products" ? id : product.product_uid
                            }`
                          );
                        }}
                      >
                        <h1 className="text3Lines">{product.product_name}</h1>
                        {product.is_grocery ? (
                          <div className="groceryTag">Grocery</div>
                        ) : null}
                        <div style={{ marginTop: "auto" }} className="flex">
                          <div className="shopDetailsInventoryPriceDiv flexColumn flexJustifyCenter">
                            <h3>
                              MRP:{" "}
                              <span style={{ textDecoration: "line-through" }}>
                                {formatter.format(product.product_mrp)}
                              </span>
                            </h3>
                            <h2>
                              Shop Price:{" "}
                              <span style={{ fontWeight: "600" }}>
                                {formatter.format(product.product_price)}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="shopDetailsInventoryNoProductsDiv flex flexJustifyAndAlignCenter">
                {productsLoading ? <ComponentLoading /> : "No Products Found"}
              </div>
            )
          ) : shopGroceryInventory[0] ? (
            shopGroceryInventory
              .filter((element) => {
                if (showAll) {
                  return true;
                } else {
                  return shopProductInventory.indexOf(element) < 10;
                }
              })
              .map((product) => {
                return (
                  <div
                    className="shopDetailsInventoryCards card-boxshadow flex"
                    key={product.product_uid}
                    title={product.product_name}
                  >
                    <div
                      id="shopDetailsInventoryImage"
                      className="flex flexJustifyAndAlignCenter"
                    >
                      <img
                        src={`${
                          process.env.NODE_ENV === "production"
                            ? "https://www.locaro.in/api"
                            : "http://localhost:5000/api"
                        }/images/products/${
                          Array.isArray(product.product_images)
                            ? product.product_images[0]
                            : "null"
                        }`}
                        alt="image"
                      />
                    </div>
                    <div
                      id="shopDetailsInventoryTextDiv"
                      className="flexColumn"
                      onClick={() => {
                        history.push(
                          `/${type === "products" ? "product" : "grocery"}/${
                            product.catalog_uid
                          }?shop=${
                            type === "products" ? id : product.product_uid
                          }`
                        );
                      }}
                    >
                      <h1 className="text3Lines">{product.product_name}</h1>
                      {product.is_grocery ? (
                        <div className="groceryTag">Grocery</div>
                      ) : null}
                      <div style={{ marginTop: "auto" }} className="flex">
                        <div className="shopDetailsInventoryPriceDiv flexColumn flexJustifyCenter">
                          <h3>
                            MRP:{" "}
                            <span style={{ textDecoration: "line-through" }}>
                              {formatter.format(product.product_mrp)}
                            </span>
                          </h3>
                          <h2>
                            Shop Price:{" "}
                            <span style={{ fontWeight: "600" }}>
                              {formatter.format(product.product_price)}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="shopDetailsInventoryNoProductsDiv flex flexJustifyAndAlignCenter">
              {productsLoading ? <ComponentLoading /> : "No Products Found"}
            </div>
          )}
          {!showAll &&
          ((type === "products" && shopProductInventory.length > 10) ||
            (type === "groceries" && shopGroceryInventory.length > 10)) ? (
            <p
              style={{
                fontFamily: "Montserrat",
                color: "#b90504",
                cursor: "pointer",
                fontSize: "18px",
                fontWeight: "500",
                backgroundColor: "white",
                padding: "10px 0px",
                borderRadius: "10px",
                boxShadow:
                  "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
              }}
              onClick={() => {
                setShowAll(true);
              }}
              className="flex flexJustifyCenter"
            >
              ...Show All
            </p>
          ) : null}
        </div>
        <Reviews setReviewOverlay={setReviewOverlay} />
      </div>
    );
  } else {
    return (
      <div id="rightDivContainer">
        <div id="shopInventoryTools" className="flex flexAlignCenter">
          <div id="shopDetailsProductTypeDiv" className="flex flexAlignCenter">
            <div
              id="shopDetailsProductTab"
              className="flex flexJustifyAndAlignCenter"
              onClick={() => {
                setType("products");
              }}
            >
              <h1>Products</h1>
            </div>
            <div
              id="shopDetailsGroceryTab"
              className="flex flexJustifyAndAlignCenter"
              onClick={() => {
                setType("groceries");
              }}
            >
              <h1>Groceries</h1>
            </div>
          </div>
          <form id="shopInventoryToolsForm" className="flex flexJustifyRight">
            <input
              type="text"
              className="shopDetailsInventorySearch"
              placeholder="Search Inventory"
              onChange={(e) => {
                searchHandler(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="shopDetailsInventoryCardParentDiv">
          {type === "products" ? (
            shopProductInventory[0] ? (
              shopProductInventory
                .filter((element) => {
                  if (showAll) {
                    return true;
                  } else {
                    return shopProductInventory.indexOf(element) < 10;
                  }
                })
                .map((product) => {
                  return (
                    <div
                      className="shopDetailsInventoryCards card-boxshadow flex"
                      key={product.product_uid}
                      title={product.product_name}
                    >
                      <div
                        id="shopDetailsInventoryImage"
                        className="flex flexJustifyAndAlignCenter"
                      >
                        <img
                          src={`${
                            process.env.NODE_ENV === "production"
                              ? "https://www.locaro.in/api"
                              : "http://localhost:5000/api"
                          }/images/products/${
                            Array.isArray(product.product_images)
                              ? product.product_images[0]
                              : "null"
                          }`}
                          alt="image"
                        />
                      </div>
                      <div
                        id="shopDetailsInventoryTextDiv"
                        className="flexColumn"
                        onClick={() => {
                          history.push(
                            `/${type === "products" ? "product" : "grocery"}/${
                              product.catalog_uid
                            }?shop=${
                              type === "products" ? id : product.product_uid
                            }`
                          );
                        }}
                      >
                        <h1 className="text3Lines">{product.product_name}</h1>
                        {product.is_grocery ? (
                          <div className="groceryTag">Grocery</div>
                        ) : null}
                        <div style={{ marginTop: "auto" }} className="flex">
                          <div className="shopDetailsInventoryPriceDiv flexColumn flexJustifyCenter">
                            <h3>
                              MRP:{" "}
                              <span style={{ textDecoration: "line-through" }}>
                                {formatter.format(product.product_mrp)}
                              </span>
                            </h3>
                            <h2>
                              Shop Price:{" "}
                              <span style={{ fontWeight: "600" }}>
                                {formatter.format(product.product_price)}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="shopDetailsInventoryNoProductsDiv flex flexJustifyAndAlignCenter">
                {productsLoading ? <ComponentLoading /> : "No Products Found"}
              </div>
            )
          ) : shopGroceryInventory[0] ? (
            shopGroceryInventory
              .filter((element) => {
                if (showAll) {
                  return true;
                } else {
                  return shopProductInventory.indexOf(element) < 10;
                }
              })
              .map((product) => {
                return (
                  <div
                    className="shopDetailsInventoryCards card-boxshadow flex"
                    key={product.product_uid}
                    title={product.product_name}
                  >
                    <div
                      id="shopDetailsInventoryImage"
                      className="flex flexJustifyAndAlignCenter"
                    >
                      <img
                        src={`${
                          process.env.NODE_ENV === "production"
                            ? "https://www.locaro.in/api"
                            : "http://localhost:5000/api"
                        }/images/products/${
                          Array.isArray(product.product_images)
                            ? product.product_images[0]
                            : "null"
                        }`}
                        alt="image"
                      />
                    </div>
                    <div
                      id="shopDetailsInventoryTextDiv"
                      className="flexColumn"
                      onClick={() => {
                        history.push(
                          `/${type === "products" ? "product" : "grocery"}/${
                            product.catalog_uid
                          }?shop=${
                            type === "products" ? id : product.product_uid
                          }`
                        );
                      }}
                    >
                      <h1 className="text3Lines">{product.product_name}</h1>
                      {product.is_grocery ? (
                        <div className="groceryTag">Grocery</div>
                      ) : null}
                      <div style={{ marginTop: "auto" }} className="flex">
                        <div className="shopDetailsInventoryPriceDiv flexColumn flexJustifyCenter">
                          <h3>
                            MRP:{" "}
                            <span style={{ textDecoration: "line-through" }}>
                              {formatter.format(product.product_mrp)}
                            </span>
                          </h3>
                          <h2>
                            Shop Price:{" "}
                            <span style={{ fontWeight: "600" }}>
                              {formatter.format(product.product_price)}
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="shopDetailsInventoryNoProductsDiv flex flexJustifyAndAlignCenter">
              {productsLoading ? <ComponentLoading /> : "No Products Found"}
            </div>
          )}
        </div>
        {!showAll &&
        ((type === "products" && shopProductInventory.length > 10) ||
          (type === "groceries" && shopGroceryInventory.length > 10)) ? (
          <p
            style={{
              fontFamily: "Montserrat",
              color: "#b90504",
              cursor: "pointer",
              fontSize: "18px",
              fontWeight: "500",
              backgroundColor: "white",
              padding: "10px 0px",
              borderRadius: "10px",
              boxShadow:
                "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            }}
            onClick={() => {
              setShowAll(true);
            }}
            className="flex flexJustifyCenter"
          >
            ...Show All
          </p>
        ) : null}
        <Reviews setReviewOverlay={setReviewOverlay} />
      </div>
    );
  }
}

export default RightDiv;

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { axiosUser } from "../../axiosInstance";

import "./styles.css";

export function SearchFilterBox({ page = "products", setMobilePopUp }) {
  const location = useLocation();
  const [filters, setFilters] = useState();

  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const radius = query.get("radius");

  const getFilters = () => {
    if (window.outerWidth > 780) {
      axiosUser
        .get("/search/filters", {
          params: {
            search,
            radius,
            type: page,
          },
        })
        .then((res) => {
          setFilters(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getFilters();
  }, [radius, search]);

  if (window.outerWidth <= 780) {
    return (
      <>
        <div
          className="mobileSearchFilterBoxButton flex flexJustifyAndAlignCenter"
          onClick={() => {
            setMobilePopUp(true);
          }}
        >
          Filters
        </div>
      </>
    );
  }

  return (
    <div id="searchPageFilterBox" className="flexColumn">
      {page === "products" ? (
        <Link
          className="flashCardSuccess textDecorNone flex flexJustifyAndAlignCenter"
          style={{
            color: "white",
            backgroundColor: "green",
            border: "none",
            fontWeight: "600",
            fontSize: "16px",
            height: "60px",
            boxSizing: "border-box",
            borderRadius: "20px",
          }}
          to={`/search/grocery?search=${search}`}
        >
          Want Groceries? Click here
        </Link>
      ) : null}
      <Brand
        brands={filters && filters.brand ? filters.brand : []}
        page={page}
      />
      <Category
        categories={filters && filters.category ? filters.category : []}
        page={page}
      />
    </div>
  );
}

export function MobileFilterPopUp({
  setMobilePopUp,
  mobilePopUp,
  page = "products",
}) {
  const [filters, setFilters] = useState();
  const location = useLocation();
  const popUp = useRef();
  const overlay = useRef();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const radius = query.get("radius");

  const getFilters = () => {
    axiosUser
      .get("/search/filters", {
        params: {
          search,
          radius,
          type: page,
        },
      })
      .then((res) => {
        setFilters(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilters();
  }, [location]);

  return (
    <>
      {mobilePopUp ? (
        <div
          className="overlayDiv"
          style={{
            touchAction: "none",
            animationDuration: "500ms",
            animationName: "overlayFadeIn",
          }}
          ref={overlay}
        >
          <div
            style={{ height: "100%" }}
            onClick={() => {
              popUp.current.style.animationName = "filtersPopDown";
              overlay.current.style.animationName = "overlayFadeOut";
              setTimeout(() => {
                popUp.current.style.animationName = "filtersPopUp";
                overlay.current.style.animationName = "overlayFadeIn";
                setMobilePopUp(false);
              }, 480);
            }}
          ></div>
          <div className="mobileFilterPopUpDiv" ref={popUp}>
            <div
              className="mobileSearchFilterBoxButton flex flexJustifyAndAlignCenter"
              style={{ position: "relative", bottom: "1px" }}
              onClick={() => {
                popUp.current.style.animationName = "filtersPopDown";
                overlay.current.style.animationName = "filtersOverlayFadeOut";
                setTimeout(() => {
                  popUp.current.style.animationName = "filtersPopUp";
                  overlay.current.style.animationName = "";
                  setMobilePopUp(false);
                }, 500);
              }}
            >
              Filters
            </div>
            <div
              style={{
                height: "calc(100% - 40px)",
                width: "100%",
                overflow: "scroll",
              }}
              className="flexColumn"
            >
              <Brand
                brands={filters && filters.brand ? filters.brand : []}
                page={page}
              />
              <Category
                categories={filters && filters.category ? filters.category : []}
                page={page}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

function Brand({ brands = [], page }) {
  const location = useLocation();
  const history = useHistory();
  const brandQuery = queryString.parse(location.search).brand;

  return (
    <div className="subFiltersDiv">
      <div className="subFiltersHeaderDiv">
        <h1 className="subFiltersHeader">Brands:</h1>
      </div>
      <div className="subFiltersContentDiv">
        {brands.map((brand) => {
          return (
            <div
              className="subFiltersCheckboxDiv flex flexAlignCenter"
              key={brand.brand}
            >
              <input
                type="checkbox"
                value={brand.brand}
                onChange={(e) => {
                  let allQueries = queryString.parse(location.search);
                  let newBrand = [];
                  if (e.target.checked === true) {
                    if (
                      allQueries.brand &&
                      typeof allQueries.brand === "string"
                    ) {
                      newBrand = [allQueries.brand, e.target.value];
                    } else if (!allQueries.brand) {
                      newBrand = [e.target.value];
                    } else {
                      newBrand = [...allQueries.brand, e.target.value];
                    }

                    allQueries = { ...allQueries, brand: newBrand };
                    const stringified = queryString.stringify(allQueries);
                    history.push(`/search/${page}?` + stringified);
                  } else if (e.target.checked === false) {
                    if (
                      allQueries.brand &&
                      typeof allQueries.brand !== "string"
                    ) {
                      newBrand = allQueries.brand.filter((element) => {
                        return element !== e.target.value;
                      });
                    }
                    allQueries = { ...allQueries, brand: newBrand };
                    const stringified = queryString.stringify(allQueries);
                    history.push(`/search/${page}?` + stringified);
                  }
                }}
                checked={
                  typeof brandQuery === "object"
                    ? brandQuery.some((element) => element === brand.brand)
                    : brandQuery === brand.brand
                }
              />
              <label>{brand.brand}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function Category({ categories = [], page }) {
  const location = useLocation();
  const history = useHistory();
  const categoryQuery = queryString.parse(location.search).category;

  return (
    <div className="subFiltersDiv">
      <div className="subFiltersHeaderDiv">
        <h1 className="subFiltersHeader">Category:</h1>
      </div>
      <div className="subFiltersContentDiv">
        {categories.map((category) => {
          return (
            <div
              className="subFiltersCheckboxDiv flex flexAlignCenter"
              key={category.category}
            >
              <input
                type="checkbox"
                value={category.category}
                onChange={(e) => {
                  let allQueries = queryString.parse(location.search);
                  let newCategory = [];
                  if (e.target.checked === true) {
                    if (
                      allQueries.category &&
                      typeof allQueries.category === "string"
                    ) {
                      newCategory = [allQueries.category, e.target.value];
                    } else if (!allQueries.category) {
                      newCategory = [e.target.value];
                    } else {
                      newCategory = [...allQueries.category, e.target.value];
                    }

                    allQueries = { ...allQueries, category: newCategory };
                    const stringified = queryString.stringify(allQueries);
                    history.push(`/search/${page}?` + stringified);
                  } else if (e.target.checked === false) {
                    if (
                      allQueries.brand &&
                      typeof allQueries.category !== "string"
                    ) {
                      newCategory = allQueries.category.filter((element) => {
                        return element !== e.target.value;
                      });
                    }
                    allQueries = { ...allQueries, category: newCategory };
                    const stringified = queryString.stringify(allQueries);
                    history.push(`/search/${page}?` + stringified);
                  }
                }}
                checked={
                  typeof categoryQuery === "object"
                    ? categoryQuery.some(
                        (element) => element === category.category
                      )
                    : categoryQuery === category.category
                }
              />
              <label>{category.category}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

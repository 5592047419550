import React, { useState } from "react";
import { Link } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";

import "./styles.css";

function Orders({ orders = [], setOrders, setCancelled }) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const cancelHandler = (delivery_uid) => {
    axiosUser
      .get(`user/order/${delivery_uid}/cancel`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setCancelled(
            "Your order has been cancelled. Your payment will be refunded in 5-7 days"
          );
          axiosUser
            .get("/user/orders")
            .then((res) => {
              console.log(res.data);
              if (res.data.status === "success") {
                setOrders(res.data.orders);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {orders.map((order) => {
        let subtotal = 0;
        subtotal = order.order_lists.reduce((prev, curr) => {
          if (curr.order_status !== "Declined") {
            return prev + curr.product_price * curr.product_count;
          } else {
            return prev;
          }
        }, 0);
        return (
          <div className="userOrdersCard flexColumn" key={order.delivery_uid}>
            <div className="userOrdersInfoTopBar flex flexAlignCenter">
              {order.delivery_status !== "Cancelled" ? (
                <div className="userOrdersInfoDiv flex flexAlignCenter">
                  Total: <span>{formatter.format(subtotal)}</span>
                </div>
              ) : null}
              <div
                className={`userOrdersDeliveryStatusTag flex flexAlignCenter ${
                  order.delivery_status === "On Route"
                    ? "On-Route"
                    : order.delivery_status
                }`}
              >
                {order.delivery_status}
              </div>
            </div>
            {order.order_lists.map((product) => {
              return (
                <div
                  className="userOrderProductCard flex"
                  key={product.order_item_uid}
                >
                  <div className="userOrderProductImageDiv flex flexJustifyAndAlignCenter">
                    <img
                      src={`${
                        process.env.NODE_ENV === "production"
                          ? "https://www.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/products/${
                        Array.isArray(product.product_images)
                          ? product.product_images[0]
                          : "null"
                      }`}
                      alt=""
                    />
                  </div>
                  <Link
                    to={`/${product.is_grocery ? "grocery" : "product"}/${
                      product.catalog_uid
                    }?shop=${
                      product.is_grocery
                        ? product.product_uid
                        : product.shop_uid
                    }`}
                    className="userOrderProductTextDiv flexColumn textDecorNone"
                  >
                    <h1 className="text2Lines">{product.product_name}</h1>
                    <h3>{product.shop_name}</h3>
                    <h2>
                      Price:{" "}
                      <span>{formatter.format(product.product_price)}</span>
                    </h2>
                  </Link>
                  {product.order_status === "Declined" ? (
                    <div className="userOrderDeclineTag flex flexAlignCenter">
                      Declined
                    </div>
                  ) : null}
                </div>
              );
            })}
            {order.delivery_status === "Pending" ? (
              <div className="userOrdersBottomBar flex flexAlignCenter">
                <button
                  className="redButton"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    cancelHandler(order.delivery_uid);
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
}

export default Orders;

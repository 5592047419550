import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";

import "./styles.css";
import { axiosUser } from "../../axiosInstance";
import ComponentLoading from "../../components/componentLoading";
import { LocationContext } from "../../Context";

SwiperCore.use([Autoplay, Pagination]);

function ShopOfferSlider() {
  const [promotions, setPromotions] = useState([]);
  const [componentLoading, setComponentLoading] = useState(false);
  const { locationCoords } = useContext(LocationContext);

  useEffect(() => {
    if (locationCoords) {
      setComponentLoading(true);
      axiosUser
        .get("/homepage/promotions")
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setPromotions(res.data.promotions);
          }
          setComponentLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setComponentLoading(false);
        });
    }
  }, [locationCoords]);

  return (
    <>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="shopOfferSwiper"
      >
        {promotions[0] ? (
          promotions.map((promotion) => {
            return (
              <SwiperSlide key={promotion.promotion_uid}>
                <Link
                  className="shopOfferImageDiv"
                  to={
                    promotion.is_products
                      ? `/promotion/${promotion.promotion_uid}`
                      : `/shop/${promotion.shop_uid}`
                  }
                >
                  {componentLoading ? (
                    <ComponentLoading />
                  ) : (
                    <img
                      src={`${
                        process.env.NODE_ENV === "production"
                          ? "https://www.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/promotions/${promotion.promotion_image}`}
                      alt=""
                    />
                  )}
                </Link>
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide>
            <div className="shopOfferImageDiv">
              <img
                src={`${
                  process.env.NODE_ENV === "production"
                    ? "https://www.locaro.in/api"
                    : "http://localhost:5000/api"
                }/images/promotions/banner_placeholder.jpg`}
                alt=""
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}

export default ShopOfferSlider;

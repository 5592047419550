import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";

import "./styles.css";
import Footer from "../../components/footer";
import Nav from "../../components/navbar";
import Orders from "./Orders";
import { useHistory } from "react-router";

function OrdersPage() {
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const location = useLocation();
  const [cancelled, setCancelled] = useState();

  useEffect(() => {
    axiosUser
      .get("/user/orders")
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setOrders(res.data.orders);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/user/login");
        }
      });
  }, []);

  return (
    <>
      <Nav />
      <div className="userOrderPageMainContainer">
        {orders[0] ? (
          <>
            <div className="userOrdersPageTopBar">
              <h1>Your Orders</h1>
            </div>
            {location.state && !cancelled ? (
              <div
                className="flashCardSuccess"
                style={{ marginBottom: "10px" }}
              >
                {location.state.message}
              </div>
            ) : null}
            {cancelled ? (
              <div
                className="flashCardSuccess"
                style={{ marginBottom: "10px" }}
              >
                {cancelled}
              </div>
            ) : null}

            <Orders
              orders={orders}
              setOrders={setOrders}
              setCancelled={setCancelled}
            />
          </>
        ) : (
          <div className="ordersPageNoOrdersDiv flexColumn flexJustifyAndAlignCenter">
            <h1>No Orders Found!</h1>
            <p>
              Start placing orders{" "}
              <Link to="/" className="textDecorNone">
                here
              </Link>
              .
            </p>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default OrdersPage;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import queryString from "query-string";

import "./styles.css";
import loadingGIF from "./assets/loading.gif";
import { axiosUser } from "../../axiosInstance";

function ManualMode({ products, getProducts, setProducts, maxPage }) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const search = query.get("search");
  const radius = query.get("radius");
  const brandQuery = queryString.parse(location.search).brand;
  const categoryQuery = queryString.parse(location.search).category;
  const [currentPage, setCurrentPage] = useState(1);
  const [adding, setAdding] = useState(false);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const increment = (product_uid) => {
    setAdding(true);
    axiosUser
      .post("/cart/cookie/set", { product_uid: product_uid })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getProducts(currentPage);
          setAdding(false);
        } else {
          getProducts(currentPage);
          setAdding(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const decrement = (product_uid) => {
    setAdding(true);
    axiosUser
      .post("/cart/cookie/set?decrease=true", {
        product_uid: product_uid,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          getProducts(currentPage);
          setAdding(false);
        } else {
          getProducts(currentPage);
          setAdding(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // intersection observer for infinite scroll : start
  const infiniteScrollOptions = {
    threshold: 0,
    rootMargin: "0px 0px -10px 0px",
  };

  const { ref, inView, entry } = useInView(infiniteScrollOptions);

  useEffect(() => {
    if (inView === true) {
      setCurrentPage(currentPage + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (currentPage > 1 && currentPage <= maxPage) {
      axiosUser
        .get("search/products", {
          params: {
            search,
            radius,
            page: currentPage,
            brand: brandQuery,
            category: categoryQuery,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setProducts([...products, ...res.data.products]);
            console.log("new products", res.data.products);
          } else {
            console.log(res.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [currentPage]);
  // interesection observer for infinite scroll : end

  return (
    <div className="grocerySearchResultsContainer flex">
      {products.map((product) => {
        return (
          <div
            className="groceryManualSearchResultCardParentDiv flexColumn"
            key={product.product_uid}
          >
            <div
              className="flex flexAlignCenter"
              style={{ marginBottom: "10px" }}
            >
              <h1 className="groceryManualSearchResultShopHeader text1Line">
                {product.shop_name}
              </h1>
              {product.in_basket ? (
                <div
                  className="groceryTag"
                  style={{ margin: "0px 0px 0px auto", height: "100%" }}
                >
                  In Basket
                </div>
              ) : null}
            </div>
            <div
              className="grocerySearchResultCards flex"
              style={{ width: "100%", borderRadius: "10px" }}
            >
              <div className="grocerySearchResultImageDiv flex flexJustifyAndAlignCenter">
                <img
                  src={`${
                    process.env.NODE_ENV === "production"
                      ? "https://www.locaro.in/api"
                      : "http://localhost:5000/api"
                  }/images/products/${
                    Array.isArray(product.product_images)
                      ? product.product_images[0]
                      : "null"
                  }`}
                  alt=""
                />
              </div>
              <Link
                to={`/grocery/${product.catalog_uid}?shop=${product.product_uid}`}
                className="grocerySearchResultTextDiv flexColumn textDecorNone"
              >
                <h3>{"> " + product.grocery_category}</h3>
                <h1 className="text2Lines">{product.product_name}</h1>
                <h2>
                  Price : <span>{formatter.format(product.product_price)}</span>
                </h2>
              </Link>
              {product.quantity === 0 ? (
                <div
                  className="grocerySearchResultAddDiv flex flexJustifyAndAlignCenter"
                  onClick={() => {
                    increment(product.product_uid);
                  }}
                >
                  &#43;
                  {adding ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.6)",
                      }}
                    ></div>
                  ) : null}
                </div>
              ) : (
                <div className="grocerySearchPageQuantityDiv flex">
                  <div
                    className="grocerySearchPageQuantityButton flex flexJustifyAndAlignCenter"
                    onClick={() => {
                      decrement(product.product_uid);
                    }}
                  >
                    &#8722;
                  </div>
                  <div className="grocerySearchPageQuantity flex flexJustifyAndAlignCenter">
                    {product.quantity}
                  </div>
                  <div
                    className="grocerySearchPageQuantityButton flex flexJustifyAndAlignCenter"
                    onClick={() => {
                      increment(product.product_uid);
                    }}
                  >
                    &#43;
                  </div>
                  {adding ? (
                    <div
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0,0,0,0.6)",
                      }}
                    ></div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div
        ref={ref}
        style={{ width: "100%" }}
        className="flex flexJustifyAndAlignCenter"
      >
        {currentPage > maxPage ? null : (
          <img
            src={loadingGIF}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        )}
      </div>
    </div>
  );
}

export default ManualMode;

import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { axiosUser } from "../../axiosInstance";
import { LoadingContext } from "../../Context";

import "./styles.css";

function ShopAddReview({ setReviewOverlay, shopDetails }) {
  const [error, setError] = useState(false);
  const [stars, setStars] = useState(3);
  const { setLoading } = useContext(LoadingContext);
  const { id } = useParams();

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const reviewBody = {
      review_rating: stars,
      review_text: e.target.reviewMessage.value,
      shop_uid: id,
    };
    axiosUser
      .post("/user/shops/review", reviewBody)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setReviewOverlay(false);
          window.location.reload();
          setLoading(false);
        } else if (res.data.message === "Letter Limit Exceeded (1500)") {
          setError("Text limit reached (1500)");
          setLoading(false);
        } else if (
          res.data.message ===
          'null value in column "order_uid" violates not-null constraint'
        ) {
          setLoading(false);
          setError(
            "You can only leave a review if you have ordered from this shop"
          );
        } else {
          setLoading(false);
          setError("You can only post 1 review per shop");
        }
      })
      .catch((err) => {
        console.log(err);
        setError("Something went wrong");
        setLoading(false);
      });
  };
  return (
    <div className="overlayDiv">
      {error ? (
        <div
          className="flashCardError"
          style={{
            marginTop: "80px",
            width: window.outerWidth < 480 ? "95%" : "400px",
            position: "absolute",
            left: "50%",
            transform: "translate(-50%,0)",
          }}
        >
          {error}
        </div>
      ) : null}
      <div className="addReviewsContainer">
        <div
          className="closeButton"
          style={{ color: "black" }}
          onClick={() => {
            setReviewOverlay(false);
          }}
        >
          &times;
        </div>
        <div className="addReviewsShopInfo flex flexJustifyAndAlignCenter">
          <h1>{shopDetails.shop_name}</h1>
        </div>
        <form
          className="addReviewsForm flexColumn flexAlignCenter"
          onSubmit={submitHandler}
        >
          <div
            className="flex flexJustifyAndAlignCenter"
            style={{ width: "100%", gap: "10px" }}
          >
            <input
              type="range"
              name="stars"
              defaultValue="3"
              min="1"
              max="5"
              step="1"
              onChange={(e) => {
                setStars(e.target.value);
              }}
            ></input>
            <div className="addReviewsSetReviewDiv">{stars} &#9733;</div>
          </div>
          <textarea name="reviewMessage" placeholder="Review Text"></textarea>
          <button className="redButton" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ShopAddReview;

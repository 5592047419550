import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";
import { LoadingContext } from "../../Context.js";

function MobileMenu({ setDropDown, userInfo }) {
  const { setLoading } = useContext(LoadingContext);
  const menu = useRef();
  const overlay = useRef();
  const logOutHandler = () => {
    setLoading(true);
    axiosUser.get("/auth/user/logout").then((res) => {
      if (res.data.status === "success") {
        console.log("logged out");
        window.location.reload();
      }
    });
  };
  return (
    <div
      className="overlayDiv flex"
      style={{ touchAction: "none", animationName: "overlayFadeIn" }}
      ref={overlay}
    >
      <div
        style={{ width: "100%", height: "100%" }}
        onClick={() => {
          menu.current.style.animationName = "menuSlideOutLeft";
          overlay.current.style.animationName = "overlayFadeOut";
          setTimeout(() => {
            setDropDown("");
          }, 480);
        }}
      ></div>
      <div className="mobileMenuDiv flexColumn" ref={menu}>
        <div className="mobileMenuLogoDiv flexColumn flexJustifyAndAlignCenter">
          <h1>Locaro</h1>
        </div>
        <div className="mobileMenuSections">
          <h1 className="mobileMenuSectionHeader">Account</h1>
          {userInfo.gotUser ? (
            <>
              <Link
                to="/"
                className="mobileMenuOptions textDecorNone flex flexAlignCenter"
              >
                Your Profile
              </Link>
              <Link
                to="/orders"
                className="mobileMenuOptions textDecorNone flex flexAlignCenter"
              >
                Orders
              </Link>
              <Link
                to="/user/address/add"
                className="mobileMenuOptions textDecorNone flex flexAlignCenter"
              >
                Add Address
              </Link>
            </>
          ) : (
            <>
              <Link
                to="/user/login"
                className="mobileMenuOptions textDecorNone flex flexAlignCenter"
              >
                Login
              </Link>
              <Link
                to="/user/register"
                className="mobileMenuOptions textDecorNone flex flexAlignCenter"
              >
                Register
              </Link>
            </>
          )}
        </div>
        <div className="mobileMenuSections">
          <h1 className="mobileMenuSectionHeader">Offers and Features</h1>
          <Link
            to="/"
            className="mobileMenuOptions textDecorNone flex flexAlignCenter"
          >
            Today's Deals
          </Link>
          <Link
            to="/"
            className="mobileMenuOptions textDecorNone flex flexAlignCenter"
          >
            Best Sellers
          </Link>
          <Link
            to="/"
            className="mobileMenuOptions textDecorNone flex flexAlignCenter"
          >
            Sell on Locaro
          </Link>
        </div>
        {userInfo.gotUser ? (
          <div
            className="mobileMenuOptions flex flexAlignCenter"
            style={{ marginTop: "auto", padding: "0px 15px" }}
            onClick={logOutHandler}
          >
            Sign Out
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MobileMenu;

import React, { useEffect, useState } from "react";

import "./styles.css";

function GroceryImage({ catalogDetails }) {
  const [currentImage, setCurrentImage] = useState();

  useEffect(() => {
    if (Array.isArray(catalogDetails.product_images)) {
      setCurrentImage(catalogDetails.product_images[0]);
    }
  }, [catalogDetails]);

  return (
    <div id="productLeftDiv" className="flexColumn">
      <div className="productLeftDivImageDiv flex flexJustifyAndAlignCenter">
        <img
          src={`${
            process.env.NODE_ENV === "production"
              ? "https://www.locaro.in/api"
              : "http://localhost:5000/api"
          }/images/products/${currentImage ? currentImage : "null"}`}
          alt=""
        />
        {currentImage ? (
          <div className="productLeftDivOtherImagesDiv flexColumn">
            {catalogDetails.product_images.map((image) => {
              return (
                <div
                  key={image}
                  className={`flex flexJustifyAndAlignCenter ${
                    image === currentImage ? "activeImage" : null
                  }`}
                  onClick={() => {
                    setCurrentImage(image);
                  }}
                >
                  <img
                    src={`${
                      process.env.NODE_ENV === "production"
                        ? "https://www.locaro.in/api"
                        : "http://localhost:5000/api"
                    }/images/products/${image}`}
                    alt=""
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default GroceryImage;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";

import ComponentLoading from "../../components/componentLoading";

import cartSVG from "./assets/cart.svg";

function RightDiv(props) {
  const { catalogDetails, shop, shopDetailsDivLoading } = props;

  const [added, setAdded] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [cartLoading, setCartLoading] = useState(false);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const addToCartHandler = () => {
    setCartLoading(true);
    if (shop) {
      axiosUser
        .post("/cart/cookie/set", { product_uid: shop.product_uid })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setAdded(true);
            setQuantity(res.data.quantity);
            setCartLoading(false);
            setTimeout(() => {
              setAdded(false);
            }, 3000);
          } else {
            setCartLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCartLoading(false);
        });
    } else {
      axiosUser
        .post("/cart/cookie/set/catalog", {
          catalog_uid: catalogDetails.catalog_uid,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setAdded(true);
            setQuantity(res.data.quantity);
            setCartLoading(false);
            setTimeout(() => {
              setAdded(false);
            }, 3000);
          } else {
            setCartLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setCartLoading(false);
        });
    }
  };

  if (window.outerWidth <= 480) {
    return (
      <div id="productsRightDiv">
        {shop ? null : (
          <div className="flashCardAlert">
            <b>Auto</b> selects the nearest shops for you. Prices may be lower
            in basket
          </div>
        )}
        <div id="selectedShopProductRightDiv">
          {shop ? (
            <>
              <h1
                className="productMiddleDivSectionHeader"
                style={{ color: "#b90504" }}
              >
                Selected Shop
              </h1>
              <div
                className="productMiddleDivSectionPriceDiv"
                style={{ marginTop: "15px" }}
              >
                <h3>
                  MRP:{" "}
                  <span style={shop ? null : { textDecoration: "none" }}>
                    {catalogDetails.product_mrp
                      ? formatter.format(catalogDetails.product_mrp)
                      : null}
                  </span>
                </h3>
                {shop ? (
                  <>
                    <h2 className="productMiddleDivSectionPrice">
                      Shop Price:{" "}
                      <span>
                        {shop ? formatter.format(shop.product_price) : null}
                      </span>
                    </h2>
                    <h4>
                      Save:{" "}
                      <span>
                        {shop
                          ? formatter.format(
                              catalogDetails.product_mrp - shop.product_price
                            )
                          : null}
                      </span>
                      <span style={{ fontSize: "13px" }}>
                        {shop
                          ? ` (${Math.floor(
                              ((catalogDetails.product_mrp -
                                shop.product_price) /
                                catalogDetails.product_mrp) *
                                100
                            )}% OFF)`
                          : null}
                      </span>
                    </h4>
                  </>
                ) : null}
              </div>
              <Link
                className="selectedShopDetailDiv  card-boxshadow flexColumn textDecorNone"
                to={`/shop/${shop.shop_uid}`}
                style={{
                  backgroundImage: `url(${
                    process.env.NODE_ENV === "production"
                      ? "https://www.locaro.in/api"
                      : "http://localhost:5000/api"
                  }/images/${shop.shop_image})`,
                }}
              >
                <div className="selectedShopDetailOverlayDiv flexColumn">
                  <h1 className="text1Line">{shop.shop_name}</h1>
                  {shop ? (
                    <div className="productRightDivRatingDiv flex">
                      <div
                        className="productRightDivRating"
                        style={{
                          backgroundColor:
                            shop.rating === null
                              ? "gray"
                              : shop.rating < 3
                              ? "#b90504"
                              : shop.rating < 4
                              ? "orange"
                              : "green",
                        }}
                      >
                        {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "}{" "}
                        &#9733;
                      </div>
                      <h1>
                        {shop.rating_count > 0 ? shop.rating_count : "No"}{" "}
                        Review
                        {shop.rating_count > 1 || shop.rating_count === "0"
                          ? "s"
                          : null}
                      </h1>
                    </div>
                  ) : null}
                  <h2 className="text2Lines">
                    {shop ? shop.shop_address : null}
                  </h2>
                </div>
              </Link>
            </>
          ) : (
            <div
              className="productMiddleDivSectionPriceDiv"
              style={{ marginTop: "15px" }}
            >
              <h3 style={{ fontSize: "20px" }}>
                MRP:{" "}
                <span style={shop ? null : { textDecoration: "none" }}>
                  {catalogDetails.product_mrp
                    ? formatter.format(catalogDetails.product_mrp)
                    : null}
                </span>
              </h3>
            </div>
          )}

          <div
            className="flexColumn flexJustifyCenter"
            id="productRightDivButtons"
          >
            <button
              className="redButton flex flexJustifyAndAlignCenter"
              onClick={addToCartHandler}
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {catalogDetails.is_grocery ? "Add To Basket" : "Add To Cart"}
              <img
                src={cartSVG}
                alt=""
                style={{ position: "absolute", right: "30px" }}
              />
              {cartLoading ? <ComponentLoading minHeight="100%" /> : null}
            </button>
            {!catalogDetails.is_grocery ? (
              <Link to="/checkout">
                <button
                  className="redButton"
                  style={{
                    width: "100%",
                    backgroundColor: "#990100",
                  }}
                >
                  Buy Now
                </button>
              </Link>
            ) : null}
          </div>
          {shopDetailsDivLoading ? <ComponentLoading /> : null}
        </div>
        {added ? (
          <div className="flashCardSuccess">
            Added to Cart. Quantity : {quantity}
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div id="productsRightDiv">
        {shop ? null : (
          <div className="flashCardAlert">
            <b>Auto</b> selects the nearest shops for you. Prices may be lower
            in basket
          </div>
        )}
        <div id="selectedShopProductRightDiv">
          {shop ? (
            <>
              <h1
                className="productMiddleDivSectionHeader"
                style={{ color: "#b90504" }}
              >
                Selected Shop
              </h1>
              <Link
                className="selectedShopDetailDiv  card-boxshadow flexColumn textDecorNone"
                to={`/shop/${shop.shop_uid}`}
                style={{
                  backgroundImage: `url(${
                    process.env.NODE_ENV === "production"
                      ? "https://www.locaro.in/api"
                      : "http://localhost:5000/api"
                  }/images/${shop.shop_image})`,
                }}
              >
                <div className="selectedShopDetailOverlayDiv flexColumn">
                  <h1 className="text1Line">{shop.shop_name}</h1>
                  {shop ? (
                    <div className="productRightDivRatingDiv flex">
                      <div
                        className="productRightDivRating"
                        style={{
                          backgroundColor:
                            shop.rating === null
                              ? "gray"
                              : shop.rating < 3
                              ? "#b90504"
                              : shop.rating < 4
                              ? "orange"
                              : "green",
                        }}
                      >
                        {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "}{" "}
                        &#9733;
                      </div>
                      <h1>
                        {shop.rating_count > 0 ? shop.rating_count : "No"}{" "}
                        Review
                        {shop.rating_count > 1 || shop.rating_count === "0"
                          ? "s"
                          : null}
                      </h1>
                    </div>
                  ) : null}
                  <h2 className="text2Lines">
                    {shop ? shop.shop_address : null}
                  </h2>
                </div>
              </Link>
            </>
          ) : null}

          <div
            className="productMiddleDivSectionPriceDiv"
            style={{ marginTop: "15px" }}
          >
            <h3>
              MRP:{" "}
              <span style={shop ? null : { textDecoration: "none" }}>
                {catalogDetails.product_mrp
                  ? formatter.format(catalogDetails.product_mrp)
                  : null}
              </span>
            </h3>
            {shop ? (
              <>
                <h2 className="productMiddleDivSectionPrice">
                  Shop Price:{" "}
                  <span>
                    {shop ? formatter.format(shop.product_price) : null}
                  </span>
                </h2>
                <h4>
                  Save:{" "}
                  <span>
                    {shop
                      ? formatter.format(
                          catalogDetails.product_mrp - shop.product_price
                        )
                      : null}
                  </span>
                  <span style={{ fontSize: "13px" }}>
                    {shop
                      ? ` (${Math.floor(
                          ((catalogDetails.product_mrp - shop.product_price) /
                            catalogDetails.product_mrp) *
                            100
                        )}% OFF)`
                      : null}
                  </span>
                </h4>
              </>
            ) : null}
          </div>
          <div
            className="flexColumn flexJustifyCenter"
            id="productRightDivButtons"
          >
            <button
              className="redButton flex flexJustifyAndAlignCenter"
              onClick={addToCartHandler}
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {catalogDetails.is_grocery ? "Add To Basket" : "Add To Cart"}
              <img
                src={cartSVG}
                alt=""
                style={{ position: "absolute", right: "30px" }}
              />
              {cartLoading ? <ComponentLoading minHeight="100%" /> : null}
            </button>
            {!catalogDetails.is_grocery ? (
              <Link to="/checkout">
                <button
                  className="redButton"
                  style={{
                    width: "100%",
                    backgroundColor: "#990100",
                  }}
                >
                  Buy Now
                </button>
              </Link>
            ) : null}
          </div>
          {shopDetailsDivLoading ? <ComponentLoading /> : null}
        </div>
        {added ? (
          <div className="flashCardSuccess">
            Added to Cart. Quantity : {quantity}
          </div>
        ) : null}
      </div>
    );
  }
}

export default RightDiv;

import React from "react";
import { Link } from "react-router-dom";

import "./styles.css";

function NotSignedIn() {
  return (
    <Link to="/user/login">
      <div
        id="notSignedInDiv"
        className="flexColumn flexJustifyCenter flexAlignStart"
      >
        <h3>Hello, user</h3>
        <h1>Sign In</h1>
      </div>
    </Link>
  );
}

export default NotSignedIn;

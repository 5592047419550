import React from "react";
import { Link } from "react-router-dom";

function GroceryCategories({ categories = [] }) {
  return (
    <div className="homePageCategoryGrid">
      {categories.map((category) => {
        return (
          <div
            className="homePageCategoryCard card-boxshadow"
            key={category.category_name}
          >
            <Link
              to={`/search/grocery?search=${category.category_name}&category=${category.category_name}`}
              className="homePageCategoryImageDiv flex flexJustifyAndAlignCenter"
            >
              <img
                src={`${
                  process.env.NODE_ENV === "production"
                    ? "https://www.locaro.in/api"
                    : "http://localhost:5000/api"
                }/images/category/${category.category_name}`}
                alt="Null"
              />
            </Link>
            <div className="homePageCategoryNameDiv flex flexJustifyAndAlignCenter">
              <p>{category.category_name}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GroceryCategories;

import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import "./styles.css";
import cart from "./assets/cart.svg";
import menuSVG from "./assets/menu.svg";
import personSVG from "./assets/person.svg";

import logo from "./assets/logo.png";
import { axiosUser } from "../../axiosInstance";
import { LoadingContext, UserContext } from "../../Context.js";
import Region from "../navbar/Region";
import SignedIn from "../navbar/SignedIn";
import NotSignedIn from "../navbar/NotSignedIn";
import Search from "../navbar/Search";
import MobileMenu from "../navbar/MobileMenu";
import CartPage from "../../PortalPages/CartPage";

function SmallNavBar(props) {
  const { searchModeProp = "products" } = props;
  const history = useHistory();
  const location = useLocation();
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const [regionText, setRegionText] = useState("Region");
  const [dropDown, setDropDown] = useState("");
  const mainSearchInput = useRef();

  const getInfo = () => {
    if (!userInfo.gotUser) {
      setLoading(true);
      axiosUser
        .get("/user/info")
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setUserInfo({ ...res.data.user, gotUser: true });
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const search = query.get("search");
    mainSearchInput.current.value = search;
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  if (window.outerWidth <= 780) {
    return (
      <>
        <div id="mobileNavBar" className="flexColumn">
          <div
            id="mobileNavFirstDiv"
            className="flex flexJustifyAndAlignCenter"
          >
            <img
              src={menuSVG}
              alt=""
              style={{ margin: "0px auto 0px 5px" }}
              onClick={() => {
                setDropDown("menu");
              }}
            />

            <Link to="/" className="textDecorNone">
              <h1>Locaro</h1>
            </Link>
            <div className="flex" style={{ margin: "0px 5px 0px auto" }}>
              <Link to="/">
                <img src={personSVG} alt="" />
              </Link>
              <div
                onClick={() => {
                  setDropDown("cart");
                }}
              >
                <img src={cart} alt="" />
              </div>
            </div>
          </div>
          <div id="mobileNavSecondDiv">
            <Search
              dropDown={dropDown}
              setDropDown={setDropDown}
              mainSearchInput={mainSearchInput}
            />
          </div>
          <Region
            userInfo={userInfo}
            setRegionText={setRegionText}
            regionText={regionText}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
        </div>
        {dropDown === "menu" ? (
          <MobileMenu setDropDown={setDropDown} userInfo={userInfo} />
        ) : null}
        {dropDown === "cart" ? <CartPage setDropDown={setDropDown} /> : null}
      </>
    );
  } else {
    return (
      <div id="smallNavDiv">
        <div id="topNav">
          <Link id="smallLogoImageDiv" to="/">
            <img src={logo} alt="Locaro" id="smallLogoImage" />
          </Link>
          <Region
            userInfo={userInfo}
            setRegionText={setRegionText}
            regionText={regionText}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
          <Search
            dropDown={dropDown}
            setDropDown={setDropDown}
            mainSearchInput={mainSearchInput}
            searchModeProp={searchModeProp}
          />
          {!userInfo.gotUser ? (
            <NotSignedIn />
          ) : (
            <SignedIn
              username={userInfo.user_full_name}
              dropDown={dropDown}
              setDropDown={setDropDown}
            />
          )}
          <div
            id="cartImageDiv"
            className="flex flexJustifyAndAlignCenter"
            onClick={() => {
              setDropDown("cart");
            }}
          >
            <img src={cart} alt="cart" id="cartImage" />
          </div>
        </div>
        {dropDown === "cart" ? <CartPage setDropDown={setDropDown} /> : null}
      </div>
    );
  }
}

export default SmallNavBar;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import ComponentLoading from "../../components/componentLoading/index";
import cartSVG from "./assets/cart.svg";
import loadingGIF from "./assets/loading.gif";

import { axiosUser } from "../../axiosInstance";
import "./styles.css";

function Cart({
  cart,
  getData,
  componentLoading,
  setComponentLoading,
  checkoutHandler,
  setDropDown,
  dataLoading,
  overlay,
}) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const cartDiv = useRef();

  const increment = (product_uid) => {
    setComponentLoading(true);
    axiosUser
      .post("/cart/cookie/set", { product_uid: product_uid })
      .then((res) => {
        setComponentLoading(false);
        if (res.data.status === "success") {
          getData();
        } else {
          getData();
        }
      })
      .catch((err) => {
        setComponentLoading(false);
        console.log(err);
      });
  };

  const decrement = (product_uid) => {
    setComponentLoading(true);
    axiosUser
      .post("/cart/cookie/set?decrease=true", {
        product_uid: product_uid,
      })
      .then((res) => {
        setComponentLoading(false);
        if (res.data.status === "success") {
          getData();
        }
      })
      .catch((err) => {
        setComponentLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {cart[0] ? (
        <div className="cartContainer flexColumn" ref={cartDiv}>
          <div className="cartTopBar flex flexAlignCenter">
            <div
              className="cartCloseButton"
              onClick={() => {
                overlay.current.style.animationName = "overlayFadeOut";
                cartDiv.current.style.animationName = "cartSlideOutRight";
                setTimeout(() => {
                  setDropDown("");
                }, 480);
              }}
            >
              &times;
            </div>
            <div style={{ marginLeft: "auto" }}>
              <img src={cartSVG} alt="" />
            </div>
          </div>
          <div className="cartShopContainer">
            {cart.map((shop) => {
              let total_price = 0;
              return (
                <div className="cartShopCard" key={shop.shop_uid}>
                  <h1 className="cartShopCardHeader">{shop.shop_name}</h1>
                  {shop.products.map((product) => {
                    total_price += product.product_price * product.quantity;
                    return (
                      <div
                        className="cartProductCard flex"
                        key={product.product_uid}
                      >
                        <div className="cartProductImageDiv flex flexJustifyAndAlignCenter">
                          <img
                            src={`${
                              process.env.NODE_ENV === "production"
                                ? "https://www.locaro.in/api"
                                : "http://localhost:5000/api"
                            }/images/products/${
                              Array.isArray(product.product_images)
                                ? product.product_images[0]
                                : "null"
                            }`}
                            alt=""
                          />
                        </div>
                        <Link
                          to={`/${product.is_grocery ? "grocery" : "product"}/${
                            product.catalog_uid
                          }?shop=${
                            product.is_grocery
                              ? product.product_uid
                              : shop.shop_uid
                          }`}
                          className="cartProductCardTextDiv flexColumn textDecorNone"
                        >
                          <h1 className="text2Lines">{product.product_name}</h1>
                          {product.is_grocery ? (
                            <div className="groceryTag">Grocery</div>
                          ) : null}
                          <h2>
                            Price :{" "}
                            <span>
                              {formatter.format(product.product_price)}
                            </span>
                          </h2>
                        </Link>
                        <div className="cartProductCardQuantityDiv flex">
                          <div
                            className="cartProductCardQuantityButton flex flexJustifyAndAlignCenter"
                            onClick={() => {
                              decrement(product.product_uid);
                            }}
                          >
                            &#8722;
                          </div>
                          <div className="cartProductCardQuantity flex flexJustifyAndAlignCenter">
                            {product.quantity}
                          </div>
                          <div
                            className="cartProductCardQuantityButton flex flexJustifyAndAlignCenter"
                            onClick={() => {
                              increment(product.product_uid);
                            }}
                          >
                            &#43;
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="cartShopCardBottomDiv flex flexAlignCenter">
                    <h2>
                      Shop Total: <span>{formatter.format(total_price)}</span>
                    </h2>
                    <button
                      className="redButton cartShopCardBottomDivCheckout"
                      onClick={() => {
                        checkoutHandler(shop);
                      }}
                    >
                      Checkout From Shop
                      {componentLoading ? (
                        <ComponentLoading minHeight="100%" />
                      ) : null}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="cartContainer flexColumn" ref={cartDiv}>
          <div className="cartTopBar flex flexAlignCenter">
            <div
              className="cartCloseButton"
              onClick={() => {
                overlay.current.style.animationName = "overlayFadeOut";
                cartDiv.current.style.animationName = "cartSlideOutRight";
                setTimeout(() => {
                  setDropDown("");
                }, 480);
              }}
            >
              &times;
            </div>
            <div style={{ marginLeft: "auto" }}>
              <img src={cartSVG} alt="" />
            </div>
          </div>
          <div
            style={{
              backgroundColor: "white",
              width: "calc(100% - 10px)",
              borderRadius: "10px",
              margin: "10px 5px",
              height: "100px",
              fontFamily: "Montserrat",
              color: "dimgray",
              textAlign: "center",
            }}
            className="flexColumn flexJustifyAndAlignCenter"
          >
            {dataLoading ? (
              <img src={loadingGIF} alt="" width="50px" height="50px" />
            ) : (
              <>
                <h2>Your Locaro Cart Is Empty!</h2>
                <p>Please add a product to checkout</p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Cart;

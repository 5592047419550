import React from "react";

import "./styles.css";
import ComponentLoading from "../../components/componentLoading";

function ShopInfoCard({ shopDetails, imageURL, infoLoading }) {
  return (
    <div className="infoCardParentDiv flexColumn flexJustifyAndAlignCenter">
      <div className="infoCard card-boxshadow flexColumn">
        <div
          className="shopInfoImageDiv"
          style={{
            backgroundImage: imageURL,
          }}
        >
          <div className="shopImageOverlayDiv flex">
            <div
              className="shopRatingDiv"
              style={{
                backgroundColor:
                  shopDetails.rating === null
                    ? "gray"
                    : shopDetails.rating < 3
                    ? "#b90504"
                    : shopDetails.rating < 4
                    ? "orange"
                    : "green",
              }}
            >
              {shopDetails.rating > 0
                ? shopDetails.rating.slice(0, 3) + " "
                : " -- "}
              &#9733;
            </div>
            <h1>
              {shopDetails.rating_count > 0 ? shopDetails.rating_count : "No"}{" "}
              Review
              {shopDetails.rating_count > 1 || shopDetails.rating_count === "0"
                ? "s"
                : null}
            </h1>
          </div>
        </div>
        <div className="shopInfoTextDiv flexColumn">
          <h1>{shopDetails ? shopDetails.shop_name : ""}</h1>
          <h2>
            {shopDetails
              ? shopDetails.shop_address + ", " + shopDetails.shop_pincode
              : null}
          </h2>
          <h3>{shopDetails ? "+91 " + shopDetails.shop_phone_number : null}</h3>
        </div>
        {infoLoading ? <ComponentLoading /> : null}
      </div>
    </div>
  );
}

export default ShopInfoCard;

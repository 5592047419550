import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";
import Cart from "./Cart";

import "./styles.css";

import { LoadingContext, UserContext } from "../../Context";
import Payment from "../../components/payment";

function CartPage({ setDropDown }) {
  const history = useHistory();
  const { userInfo } = useContext(UserContext);
  const [cart, setCart] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [componentLoading, setComponentLoading] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectedShop, setSelectedShop] = useState();
  const overlay = useRef();

  const getData = () => {
    setDataLoading(true);
    axiosUser
      .get("/cart/cookie/get")
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setCart(res.data.cart);
        } else if (res.data.message === "Cart Empty") {
          setCart([]);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataLoading(false);
      });
  };

  const checkoutHandler = (shop) => {
    if (userInfo.gotUser) {
      setSelectedShop(shop);
      setOpenPayment(true);
    } else {
      history.push("/user/login");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log(cart);
  }, [cart]);

  return (
    <div
      className="overlayDiv"
      style={{ touchAction: "none", animationName: "overlayFadeIn" }}
      ref={overlay}
    >
      <Cart
        cart={cart}
        setCart={setCart}
        getData={getData}
        componentLoading={componentLoading}
        setComponentLoading={setComponentLoading}
        checkoutHandler={checkoutHandler}
        setDropDown={setDropDown}
        dataLoading={dataLoading}
        overlay={overlay}
      />
      {openPayment ? (
        <Payment selectedShop={selectedShop} setOpenPayment={setOpenPayment} />
      ) : null}
    </div>
  );
}

export default CartPage;

import React from "react";
import { Link, useHistory } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";
import "./styles.css";

function DropDown() {
  const history = useHistory();
  const logOutHandler = () => {
    axiosUser.get("/auth/user/logout").then((res) => {
      if (res.data.status === "success") {
        console.log("logged out");
        window.location.reload();
      }
    });
  };

  return (
    <React.Fragment>
      <div id="dropDownDiv" className="flex">
        <div id="accountColumnDropDownDiv" className="flexColumn flexAlignEnd">
          <h1>Account</h1>
          <h3>Your Account</h3>
          <Link to="/orders">
            <h3>Orders</h3>
          </Link>
          <Link to="/user/address/add">
            <h3>Add Address</h3>
          </Link>

          <h2 onClick={logOutHandler}>Sign Out</h2>
        </div>
      </div>
      <div id="navDropDownOverlay"></div>
    </React.Fragment>
  );
}

export default DropDown;

import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loading from "./components/loading";

import Home from "./PortalPages/HomePage";
import LoginPage from "./AuthPages/UserLoginPage";
import RegisterPage from "./AuthPages/UserRegisterPage";
import AddAddressPage from "./PortalPages/AddAddressPage";
import ProductSearchPage from "./PortalPages/ProductsSearchPage";
import ProductsPage from "./PortalPages/ProductsPage";

import { LoadingContext, LocationContext, UserContext } from "./Context.js";
import ShopDetailsPage from "./PortalPages/ShopDetailsPage";
import ErrorPage from "./PortalPages/ErrorPage";
import GrocerySearchPage from "./PortalPages/GrocerySearchPage";
import GroceryPage from "./PortalPages/GroceryPage";
import OrdersPage from "./PortalPages/OrdersPage";

import DemoWarning from "./components/demoModeWarning";
import PromotionPage from "./PortalPages/PromotionPage";

const App = () => {
  const [locationCoords, setLocationCoords] = useState();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({ gotUser: false });
  const [warning, setWarning] = useState(true);

  useEffect(() => {
    console.log(userInfo);
  }, [userInfo]);

  return (
    <LoadingContext.Provider value={{ setLoading, loading }}>
      <LocationContext.Provider value={{ setLocationCoords, locationCoords }}>
        <UserContext.Provider value={{ userInfo, setUserInfo }}>
          <DemoWarning warning={warning} setWarning={setWarning} />
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
                {loading ? <Loading /> : null}
              </Route>
              <Route exact path="/user/login">
                <LoginPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route exact path="/user/register">
                <RegisterPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route exact path="/user/address/add">
                <AddAddressPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/search/products">
                <ProductSearchPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/search/grocery">
                <GrocerySearchPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/product/:id">
                <ProductsPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/grocery/:id">
                <GroceryPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/shop/:id">
                <ShopDetailsPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route exact path="/orders">
                <OrdersPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="/promotion/:id">
                <PromotionPage />
                {loading ? <Loading /> : null}
              </Route>
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
          </Router>
        </UserContext.Provider>
      </LocationContext.Provider>
    </LoadingContext.Provider>
  );
};

export default App;

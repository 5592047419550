import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";

import ComponentLoading from "../../components/componentLoading";

import cartSVG from "./assets/cart.svg";

function RightDiv(props) {
  const { catalogDetails, shop, shopDetailsDivLoading, productListings } =
    props;

  const [added, setAdded] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [cartLoading, setCartLoading] = useState(false);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const addToCartHandler = () => {
    setCartLoading(true);
    axiosUser
      .post("/cart/cookie/set", { product_uid: shop.product_uid })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setAdded(true);
          setQuantity(res.data.quantity);
          setCartLoading(false);
          setTimeout(() => {
            setAdded(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setCartLoading(false);
        console.log(err);
      });
  };
  if (window.outerWidth <= 480) {
    return (
      <div id="productsRightDiv">
        {shop ? (
          <div id="selectedShopProductRightDiv">
            <h1
              className="productMiddleDivSectionHeader"
              style={{ color: "#b90504" }}
            >
              {shop && shop.shop_uid === productListings[0].shop_uid
                ? "Best Offer"
                : "Selected Shop"}
            </h1>
            <div
              className="productMiddleDivSectionPriceDiv"
              style={{ marginTop: "15px" }}
            >
              <h3>
                MRP:{" "}
                <span>
                  {catalogDetails.product_mrp
                    ? formatter.format(catalogDetails.product_mrp)
                    : null}
                </span>
              </h3>
              <h2 className="productMiddleDivSectionPrice">
                Shop Price:{" "}
                <span>
                  {shop ? formatter.format(shop.product_price) : null}
                </span>
              </h2>
              <h4>
                Save:{" "}
                <span>
                  {shop
                    ? formatter.format(
                        catalogDetails.product_mrp - shop.product_price
                      )
                    : null}
                </span>
                <span style={{ fontSize: "13px" }}>
                  {shop
                    ? ` (${Math.floor(
                        ((catalogDetails.product_mrp - shop.product_price) /
                          catalogDetails.product_mrp) *
                          100
                      )}% OFF)`
                    : null}
                </span>
              </h4>
            </div>
            <Link
              className="selectedShopDetailDiv  card-boxshadow flexColumn textDecorNone"
              to={`/shop/${shop.shop_uid}`}
              style={{
                backgroundImage: `url(${
                  process.env.NODE_ENV === "production"
                    ? "https://www.locaro.in/api"
                    : "http://localhost:5000/api"
                }/images/${shop.shop_image})`,
              }}
            >
              <div className="selectedShopDetailOverlayDiv flexColumn">
                <h1 className="text1Line">{shop.shop_name}</h1>
                {shop ? (
                  <div className="productRightDivRatingDiv flex">
                    <div
                      className="productRightDivRating"
                      style={{
                        backgroundColor:
                          shop.rating === null
                            ? "gray"
                            : shop.rating < 3
                            ? "#b90504"
                            : shop.rating < 4
                            ? "orange"
                            : "green",
                      }}
                    >
                      {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "}{" "}
                      &#9733;
                    </div>
                    <h1>
                      {shop.rating_count > 0 ? shop.rating_count : "No"} Review
                      {shop.rating_count > 1 || shop.rating_count === "0"
                        ? "s"
                        : null}
                    </h1>
                  </div>
                ) : null}
                <h2 className="text2Lines">{shop.shop_address}</h2>
              </div>
            </Link>
            <div
              className="flexColumn flexJustifyCenter"
              id="productRightDivButtons"
            >
              <button
                className="redButton flex flexJustifyAndAlignCenter"
                onClick={addToCartHandler}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {catalogDetails.is_grocery ? "Add To Basket" : "Add To Cart"}
                <img
                  src={cartSVG}
                  alt=""
                  style={{ position: "absolute", right: "30px" }}
                />
                {cartLoading ? <ComponentLoading minHeight="100%" /> : null}
              </button>
              {!catalogDetails.is_grocery ? (
                <Link to="/checkout">
                  <button
                    className="redButton"
                    style={{
                      width: "100%",
                      backgroundColor: "#990100",
                    }}
                  >
                    Buy Now
                  </button>
                </Link>
              ) : null}
            </div>
            {shopDetailsDivLoading ? <ComponentLoading /> : null}
          </div>
        ) : (
          <div
            id="bestOfferProudctsRightDiv"
            className="flex flexJustifyAndAlignCenter"
            style={{ height: "250px" }}
          >
            <h1
              className="productMiddleDivSectionHeader"
              style={{ color: "#b90504", margin: "0", fontSize: "23px" }}
            >
              No offers near you
            </h1>
          </div>
        )}
        {added ? (
          <div className="flashCardSuccess">
            Added to Cart. Quantity : {quantity}
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div id="productsRightDiv">
      {shop ? (
        <div id="selectedShopProductRightDiv">
          <h1
            className="productMiddleDivSectionHeader"
            style={{ color: "#b90504" }}
          >
            {shop && shop.shop_uid === productListings[0].shop_uid
              ? "Best Offer"
              : "Selected Shop"}
          </h1>
          <Link
            className="selectedShopDetailDiv  card-boxshadow flexColumn textDecorNone"
            to={`/shop/${shop.shop_uid}`}
            style={{
              backgroundImage: `url(${
                process.env.NODE_ENV === "production"
                  ? "https://www.locaro.in/api"
                  : "http://localhost:5000/api"
              }/images/${shop.shop_image})`,
            }}
          >
            <div className="selectedShopDetailOverlayDiv flexColumn">
              <h1 className="text1Line">{shop.shop_name}</h1>
              {shop ? (
                <div className="productRightDivRatingDiv flex">
                  <div
                    className="productRightDivRating"
                    style={{
                      backgroundColor:
                        shop.rating === null
                          ? "gray"
                          : shop.rating < 3
                          ? "#b90504"
                          : shop.rating < 4
                          ? "orange"
                          : "green",
                    }}
                  >
                    {shop.rating > 0 ? shop.rating.slice(0, 3) : " -- "} &#9733;
                  </div>
                  <h1>
                    {shop.rating_count > 0 ? shop.rating_count : "No"} Review
                    {shop.rating_count > 1 || shop.rating_count === "0"
                      ? "s"
                      : null}
                  </h1>
                </div>
              ) : null}
              <h2 className="text2Lines">{shop.shop_address}</h2>
            </div>
          </Link>
          <div
            className="productMiddleDivSectionPriceDiv"
            style={{ marginTop: "15px" }}
          >
            <h3>
              MRP:{" "}
              <span>
                {catalogDetails.product_mrp
                  ? formatter.format(catalogDetails.product_mrp)
                  : null}
              </span>
            </h3>
            <h2 className="productMiddleDivSectionPrice">
              Shop Price:{" "}
              <span>{shop ? formatter.format(shop.product_price) : null}</span>
            </h2>
            <h4>
              Save:{" "}
              <span>
                {shop
                  ? formatter.format(
                      catalogDetails.product_mrp - shop.product_price
                    )
                  : null}
              </span>
              <span style={{ fontSize: "13px" }}>
                {shop
                  ? ` (${Math.floor(
                      ((catalogDetails.product_mrp - shop.product_price) /
                        catalogDetails.product_mrp) *
                        100
                    )}% OFF)`
                  : null}
              </span>
            </h4>
          </div>
          <div
            className="flexColumn flexJustifyCenter"
            id="productRightDivButtons"
          >
            <button
              className="redButton flex flexJustifyAndAlignCenter"
              onClick={addToCartHandler}
              style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {catalogDetails.is_grocery ? "Add To Basket" : "Add To Cart"}
              <img
                src={cartSVG}
                alt=""
                style={{ position: "absolute", right: "30px" }}
              />
              {cartLoading ? <ComponentLoading minHeight="100%" /> : null}
            </button>
            {!catalogDetails.is_grocery ? (
              <Link to="/checkout">
                <button
                  className="redButton"
                  style={{
                    width: "100%",
                    backgroundColor: "#990100",
                  }}
                >
                  Buy Now
                </button>
              </Link>
            ) : null}
          </div>
          {shopDetailsDivLoading ? <ComponentLoading /> : null}
        </div>
      ) : (
        <div
          id="selectedShopProductRightDiv"
          className="flex flexJustifyAndAlignCenter"
          style={{ height: "250px" }}
        >
          <h1
            className="productMiddleDivSectionHeader"
            style={{ color: "#b90504", margin: "0", fontSize: "23px" }}
          >
            No offers near you
          </h1>
        </div>
      )}
      {added ? (
        <div className="flashCardSuccess">
          Added to Cart. Quantity : {quantity}
        </div>
      ) : null}
    </div>
  );
}

export default RightDiv;

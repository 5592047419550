import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";
import { LoadingContext } from "../../Context";
import "./styles.css";

function LoginPage() {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const loginData = {
      userEmail: e.target.userEmail.value,
      password: e.target.password.value,
    };
    axiosUser
      .post("/auth/user/login", loginData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          history.push("/");
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
  };
  return (
    <div>
      <div id="loginBodyTop">
        <h1>Locaro</h1>
      </div>
      <div id="loginBodyBottom"></div>
      <div id="loginContainer">
        <form id="loginForm" onSubmit={onSubmitHandler}>
          <div className="animatedInputDivs">
            <input
              type="text"
              name="userEmail"
              id="userEmailLoginInput"
              placeholder=" "
              autoComplete="off"
              className="animatedInputInputs"
            ></input>
            <label
              htmlFor="userEmailLoginInput"
              className="animatedInputLabels"
            >
              Email
            </label>
          </div>
          <div className="animatedInputDivs">
            <input
              type="password"
              name="password"
              id="userPasswordLoginInput"
              placeholder=" "
              autoComplete="off"
              className="animatedInputInputs"
            ></input>
            <label
              htmlFor="userPasswordLoginInput"
              className="animatedInputLabels"
            >
              Password
            </label>
          </div>
          <button type="submit" style={{ marginTop: "10px" }}>
            Login
          </button>
        </form>
        <Link to="/user/register" id="goToLoginAndRegister">
          Register
        </Link>
      </div>
    </div>
  );
}

export default LoginPage;

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import queryString from "query-string";

import SmallNavBar from "../../components/smallNavBar";

import "./styles.css";
import GroceryManualPage from "./GroceryManualPage";
import Footer from "../../components/footer";
import { MobileFilterPopUp } from "../../components/searchFilterBox";

function GrocerySearchPage() {
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const history = useHistory();
  const [queryStrings, setQueryStrings] = useState();

  useEffect(() => {
    setQueryStrings(queryString.parse(location.search));
  }, []);

  useEffect(() => {
    const correctedQueryString = { ...queryStrings, search: search };
    const stringified = queryString.stringify(correctedQueryString);
    history.push("/search/grocery?" + stringified);
  }, [queryStrings]);

  return (
    <>
      <SmallNavBar searchModeProp="grocery" />
      <GroceryManualPage
        queryStrings={queryStrings}
        setQueryStrings={setQueryStrings}
        setMobilePopUp={setMobilePopUp}
      />
      <MobileFilterPopUp
        setMobilePopUp={setMobilePopUp}
        mobilePopUp={mobilePopUp}
        page="grocery"
      />
      <Footer />
    </>
  );
}

export default GrocerySearchPage;

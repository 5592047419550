import React, { useEffect, useState } from "react";

import "./styles.css";
import Nav from "../../components/navbar/index";
import Footer from "../../components/footer/index";
import OfferSlider from "./OfferSlider";
import HorizontalSwiper from "./HorizontalSwiper";
import ShopsNearby from "./ShopsNearby";
import ShopOfferSlider from "./ShopOfferSlider";
import ProductCategories from "./ProductCategories";
import GroceryCategories from "./GroceryCategories";
import { axiosUser } from "../../axiosInstance";

function HomePage() {
  const [productCategories, setProductCategories] = useState([]);
  const [groceryCategories, setGroceryCategories] = useState([]);

  useEffect(() => {
    axiosUser
      .get("/homepage/categories")
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setProductCategories(res.data.product_category);
          setGroceryCategories(res.data.grocery_category);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <React.Fragment>
      <Nav />
      <div id="homePageMainContainer">
        <div className="offersAndShopContainer">
          <ShopOfferSlider />
          <h1>Shops Near You</h1>
          <ShopsNearby />
        </div>
        <HorizontalSwiper />
        <div className="componentContainer" id="productCategories">
          <h1>Product Categories</h1>
          <ProductCategories categories={productCategories} />
        </div>
        <div className="componentContainer" id="groceryCategories">
          <h1>Grocery Categories</h1>
          <GroceryCategories categories={groceryCategories} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default HomePage;

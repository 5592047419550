import React, { useState, useEffect, useContext } from "react";

import RegionDropDown from "./RegionDropDown";

import "./styles.css";
import locationSVG from "./assets/location.svg";
import locationDropperSVG from "./assets/locationDropper.svg";

import { axiosUser } from "../../axiosInstance";
import { LoadingContext, LocationContext } from "../../Context.js";

function Region(props) {
  const [currentLocation, setCurrentLocation] = useState({
    longitude: 73.0755886218017,
    latitude: 19.03439386439006,
  });
  const { userInfo, regionText, setRegionText, dropDown, setDropDown } = props;
  const { setLoading } = useContext(LoadingContext);
  const { setLocationCoords } = useContext(LocationContext);
  const [showError, setShowError] = useState(false);

  // useEffect(() => {
  //   if (!userInfo.gotUser) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         setCurrentLocation({
  //           latitude: position.coords.latitude,
  //           longitude: position.coords.longitude,
  //         });
  //       },
  //       () => {
  //         console.log("Unable to retrieve your location");
  //         setDropDown("region");
  //         setShowError(true);
  //       }
  //     );
  //   } else {
  //     // setCurrentLocation({});
  //   }
  // }, []);

  useEffect(() => {
    axiosUser
      .post("map/setlocation/check", { location: currentLocation })
      .then((res) => {
        if (res.data.status === "success") {
          console.log("got location cookie");
          axiosUser
            .get("map/getlocation")
            .then((res) => {
              if (res.data.status === "success") {
                setRegionText(res.data.location);
                setLocationCoords(res.data.coords);
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err.message);
              setLoading(false);
            });
        } else if (res.data.status === "failed") {
          axiosUser
            .get("map/getlocation")
            .then((res) => {
              if (res.data.status === "success") {
                setRegionText(res.data.location);
                setLocationCoords(res.data.coords);
                setShowError(false);
                setDropDown("");
                setLoading(false);
              } else {
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err.message);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  }, [currentLocation]);

  if (window.outerWidth <= 780) {
    return (
      <>
        <div
          className="flex flexJustifyAndAlignCenter"
          id="mobileNavThirdDiv"
          onClick={() => {
            if (dropDown === "region") {
              setDropDown("");
            } else {
              setDropDown("region");
            }
          }}
        >
          <img src={locationDropperSVG} alt="" />
          <h1 className="text1Line">{regionText}</h1>
        </div>
        {dropDown === "region" ? (
          <RegionDropDown
            currentLocation={currentLocation}
            showError={showError}
            userInfo={userInfo}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
        ) : null}
      </>
    );
  } else {
    return (
      <React.Fragment>
        <div
          id="regionDiv"
          className="flex flexJustifyAndAlignCenter"
          onClick={() => {
            if (dropDown === "region") {
              setDropDown("");
            } else {
              setDropDown("region");
            }
          }}
        >
          <h1>
            {regionText.length <= 8
              ? regionText
              : regionText.slice(0, 7) + "..."}
          </h1>
          <img src={locationSVG} alt="" />
        </div>
        {dropDown === "region" ? (
          <RegionDropDown
            currentLocation={currentLocation}
            showError={showError}
            userInfo={userInfo}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Region;

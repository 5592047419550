import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import NotSignedIn from "./NotSignedIn";
import SignedIn from "./SignedIn";
import Region from "./Region";

import "./styles.css";
import logo from "./assets/logo.png";
import cart from "./assets/cart.svg";
import menuSVG from "./assets/menu.svg";
import personSVG from "./assets/person.svg";

import { axiosUser } from "../../axiosInstance.js";
import { LoadingContext, UserContext } from "../../Context.js";
import Search from "./Search";
import MobileMenu from "./MobileMenu";
import CartPage from "../../PortalPages/CartPage";

function Nav() {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);
  const [regionText, setRegionText] = useState("Region");
  const [dropDown, setDropDown] = useState("");
  const mainSearchInput = useRef();

  const getInfo = () => {
    if (!userInfo.gotUser) {
      setLoading(true);
      axiosUser
        .get("/user/info")
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setUserInfo({ ...res.data.user, gotUser: true });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  if (window.outerWidth <= 780) {
    return (
      <>
        <div id="mobileNavBar" className="flexColumn">
          <div
            id="mobileNavFirstDiv"
            className="flex flexJustifyAndAlignCenter"
          >
            <img
              src={menuSVG}
              alt=""
              style={{ margin: "0px auto 0px 5px" }}
              onClick={() => {
                setDropDown("menu");
              }}
            />
            <Link to="/" className="textDecorNone">
              <h1>Locaro</h1>
            </Link>
            <div className="flex" style={{ margin: "0px 5px 0px auto" }}>
              <Link to={userInfo.gotUser ? "/" : "/user/login"}>
                <img src={personSVG} alt="" />
              </Link>
              <div
                onClick={() => {
                  setDropDown("cart");
                }}
              >
                <img src={cart} alt="" />
              </div>
            </div>
          </div>
          <div id="mobileNavSecondDiv">
            <Search
              dropDown={dropDown}
              setDropDown={setDropDown}
              mainSearchInput={mainSearchInput}
            />
          </div>
          <Region
            userInfo={userInfo}
            setRegionText={setRegionText}
            regionText={regionText}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
        </div>
        {dropDown === "menu" ? (
          <MobileMenu setDropDown={setDropDown} userInfo={userInfo} />
        ) : null}
        {dropDown === "cart" ? <CartPage setDropDown={setDropDown} /> : null}
      </>
    );
  } else {
    return (
      <div id="navDiv">
        <Link to="/">
          <img src={logo} alt="Locaro" id="logoImage" />
        </Link>
        <div id="topNav">
          <Region
            userInfo={userInfo}
            setRegionText={setRegionText}
            regionText={regionText}
            dropDown={dropDown}
            setDropDown={setDropDown}
          />
          <Search
            dropDown={dropDown}
            setDropDown={setDropDown}
            mainSearchInput={mainSearchInput}
          />
          {!userInfo.gotUser ? (
            <NotSignedIn />
          ) : (
            <SignedIn
              username={userInfo.user_full_name}
              dropDown={dropDown}
              setDropDown={setDropDown}
            />
          )}
          <div
            id="cartImageDiv"
            className="flex flexJustifyAndAlignCenter"
            onClick={() => {
              setDropDown("cart");
            }}
          >
            <img src={cart} alt="cart" id="cartImage" />
          </div>
        </div>
        <div id="bottomNav" className="flex flexAlignCenter">
          {regionText === "Region" ? (
            <h3 id="infoH3">Click on Region to see deals near you</h3>
          ) : null}
          <div className="flex flexJustifyRight flexAlignCenter bottomNavRightDiv">
            <div className="flex flexJustifyRight flexAlignCenter bottomNavLeftDiv">
              <a href="#groceryCategories" className="textDecorNone">
                <h1>Groceries</h1>
              </a>
              <a href="#productCategories" className="textDecorNone">
                <h1>Products</h1>
              </a>
            </div>
            <div className="flex" style={{ gap: "30px" }}>
              {regionText !== "Region" ? (
                <React.Fragment>
                  <Link to="/">
                    <h1>Today's Deals</h1>
                  </Link>
                  <Link to="/">
                    <h1>Best Sellers</h1>
                  </Link>
                </React.Fragment>
              ) : null}
              <a href="https://business.locaro.in/" target="_blank">
                <h1>Sell on Locaro</h1>
              </a>
            </div>
          </div>
        </div>
        {dropDown === "cart" ? <CartPage setDropDown={setDropDown} /> : null}
      </div>
    );
  }
}

export default Nav;

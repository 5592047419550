import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import ReactMaps, { GeolocateControl, Marker, Popup } from "react-map-gl";

import "./styles.css";
import shopMarker from "./assets/shopMarker.png";
import bestShopMarker from "./assets/bestShopMarker.png";

import SmallNavBar from "../../components/smallNavBar";
import MiddleDiv from "./MiddleDiv";
import LeftDiv from "./LeftDiv";
import RightDiv from "./RightDiv";
import Footer from "../../components/footer";
import ProductAddReview from "../../components/productAddReview";

import { LocationContext, UserContext } from "../../Context";
import { axiosUser } from "../../axiosInstance.js";
import ComponentLoading from "../../components/componentLoading";
import mapboxgl from "mapbox-gl";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved */

function ProductsPage() {
  const { userInfo } = useContext(UserContext);
  const { locationCoords } = useContext(LocationContext);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const shopQuery = query.get("shop");

  const [viewport, setViewport] = useState({
    latitude: 19.0701,
    longitude: 72.8446,
    zoom: 12,
    width: "100%",
    height: "350px",
  });

  const geolocateControlStyle = {
    right: 10,
    top: 10,
  };

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const [catalogDetails, setCatalogDetails] = useState({});
  const [productListings, setProductListings] = useState([]);
  const [shop, setShop] = useState("");
  const [hoveredMarker, setHoveredMarker] = useState();
  const [reviewsOverlay, setReviewsOverlay] = useState(false);

  const [productMainDivLoading, setProductMainDivLoading] = useState(true);
  const [productPriceDivLoading, setProductPriceDivLoading] = useState(true);
  const [shopDetailsDivLoading, setShopDetailsDivLoading] = useState(true);

  const getProduct = () => {
    axiosUser
      .get(`search/products/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setCatalogDetails(res.data.catalog);
          setProductMainDivLoading(false);
          setProductListings(res.data.listings);
          setProductPriceDivLoading(false);
          if (res.data.listings) {
            if (shopQuery) {
              setShop(
                res.data.listings.find(
                  (shopListing) => shopListing.shop_uid === shopQuery
                )
              );
            } else {
              setShop(res.data.listings[0]);
            }
          }
          setShopDetailsDivLoading(false);
        } else {
          history.push("/error");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    setViewport({ ...viewport, ...locationCoords });
  }, [locationCoords]);

  if (window.outerWidth <= 480) {
    return (
      <>
        <SmallNavBar />
        <div id="productPageMainContainer">
          <MiddleDiv
            setReviewsOverlay={setReviewsOverlay}
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
            productListings={productListings}
            shopDetailsDivLoading={shopDetailsDivLoading}
          >
            <ReactMaps
              {...viewport}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onViewportChange={(viewport) => {
                setViewport(viewport);
              }}
            >
              <GeolocateControl
                style={geolocateControlStyle}
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
                auto
                onViewportChange={() => {
                  return viewport;
                }}
              />

              {productListings.map((element) => {
                return (
                  <React.Fragment key={element.product_uid}>
                    <Marker
                      longitude={element.st_x}
                      latitude={element.st_y}
                      offsetLeft={-16}
                      offsetTop={-50}
                    >
                      <img
                        src={
                          element.product_uid !== productListings[0].product_uid
                            ? shopMarker
                            : bestShopMarker
                        }
                        alt=""
                        width="32px"
                        height="50px"
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => {
                          setHoveredMarker(element);
                        }}
                        onMouseLeave={() => {
                          setHoveredMarker(null);
                        }}
                        onClick={() => {
                          setShop(element);
                        }}
                      />
                    </Marker>
                    {hoveredMarker &&
                    hoveredMarker.shop_uid === element.shop_uid ? (
                      <Popup
                        longitude={element.st_x}
                        latitude={element.st_y}
                        offsetTop={-50}
                        closeButton={false}
                      >
                        <div
                          className="mapMarkerHoverDiv"
                          style={{
                            backgroundImage: `url(${
                              process.env.NODE_ENV === "production"
                                ? "https://www.locaro.in/api"
                                : "http://localhost:5000/api"
                            }/images/${element.shop_image})`,
                          }}
                        >
                          <div className="mapPopUpOverlayDiv flexColumn flexAlignCenter">
                            <h1>
                              {shop
                                ? element.shop_name.length <= 28
                                  ? element.shop_name
                                  : element.shop_name.slice(0, 28) + "..."
                                : null}
                            </h1>
                            {shop ? (
                              <div className="mapPopUpRatingDiv flex">
                                <div className="mapPopUpRating">
                                  4.5 &#9733;
                                </div>
                              </div>
                            ) : null}
                            <h2>{formatter.format(element.product_price)}</h2>
                          </div>
                        </div>
                      </Popup>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {productMainDivLoading ? <ComponentLoading /> : null}
            </ReactMaps>
          </MiddleDiv>
        </div>
        {reviewsOverlay ? (
          <ProductAddReview
            catalogDetails={catalogDetails}
            setReviewsOverlay={setReviewsOverlay}
          />
        ) : null}
        <Footer />
      </>
    );
  } else if (window.outerWidth > 480 && window.outerWidth <= 1200) {
    return (
      <>
        <SmallNavBar />
        <div id="productPageMainContainer">
          <MiddleDiv
            setReviewsOverlay={setReviewsOverlay}
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
            productListings={productListings}
            shopDetailsDivLoading={shopDetailsDivLoading}
          >
            <ReactMaps
              {...viewport}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onViewportChange={(viewport) => {
                setViewport(viewport);
              }}
            >
              <GeolocateControl
                style={geolocateControlStyle}
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
                auto
                onViewportChange={() => {
                  return viewport;
                }}
              />

              {productListings.map((element) => {
                return (
                  <React.Fragment key={element.product_uid}>
                    <Marker
                      longitude={element.st_x}
                      latitude={element.st_y}
                      offsetLeft={-16}
                      offsetTop={-50}
                    >
                      <img
                        src={
                          element.product_uid !== productListings[0].product_uid
                            ? shopMarker
                            : bestShopMarker
                        }
                        alt=""
                        width="32px"
                        height="50px"
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => {
                          setHoveredMarker(element);
                        }}
                        onMouseLeave={() => {
                          setHoveredMarker(null);
                        }}
                        onClick={() => {
                          setShop(element);
                        }}
                      />
                    </Marker>
                    {hoveredMarker &&
                    hoveredMarker.shop_uid === element.shop_uid ? (
                      <Popup
                        longitude={element.st_x}
                        latitude={element.st_y}
                        offsetTop={-50}
                        closeButton={false}
                      >
                        <div
                          className="mapMarkerHoverDiv"
                          style={{
                            backgroundImage: `url(${
                              process.env.NODE_ENV === "production"
                                ? "https://www.locaro.in/api"
                                : "http://localhost:5000/api"
                            }/images/${element.shop_image})`,
                          }}
                        >
                          <div className="mapPopUpOverlayDiv flexColumn flexAlignCenter">
                            <h1>
                              {shop
                                ? element.shop_name.length <= 28
                                  ? element.shop_name
                                  : element.shop_name.slice(0, 28) + "..."
                                : null}
                            </h1>
                            {shop ? (
                              <div className="mapPopUpRatingDiv flex">
                                <div className="mapPopUpRating">
                                  4.5 &#9733;
                                </div>
                              </div>
                            ) : null}
                            <h2>{formatter.format(element.product_price)}</h2>
                          </div>
                        </div>
                      </Popup>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {productMainDivLoading ? <ComponentLoading /> : null}
            </ReactMaps>
          </MiddleDiv>
          <RightDiv
            catalogDetails={catalogDetails}
            shop={shop}
            productListings={productListings}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
        </div>
        {reviewsOverlay ? (
          <ProductAddReview
            catalogDetails={catalogDetails}
            setReviewsOverlay={setReviewsOverlay}
          />
        ) : null}
        <Footer />
      </>
    );
  } else if (window.outerWidth > 1200) {
    return (
      <React.Fragment>
        <SmallNavBar />
        <div id="productPageMainContainer">
          <LeftDiv catalogDetails={catalogDetails} />
          <MiddleDiv
            setReviewsOverlay={setReviewsOverlay}
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
            productListings={productListings}
          >
            <ReactMaps
              {...viewport}
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onViewportChange={(viewport) => {
                setViewport(viewport);
              }}
            >
              <GeolocateControl
                style={geolocateControlStyle}
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
                auto
                onViewportChange={() => {
                  return viewport;
                }}
              />

              {productListings.map((element) => {
                return (
                  <React.Fragment key={element.product_uid}>
                    <Marker
                      longitude={element.st_x}
                      latitude={element.st_y}
                      offsetLeft={-16}
                      offsetTop={-50}
                    >
                      <img
                        src={
                          element.product_uid !== productListings[0].product_uid
                            ? shopMarker
                            : bestShopMarker
                        }
                        alt=""
                        width="32px"
                        height="50px"
                        style={{ cursor: "pointer" }}
                        onMouseEnter={() => {
                          setHoveredMarker(element);
                        }}
                        onMouseLeave={() => {
                          setHoveredMarker(null);
                        }}
                        onClick={() => {
                          setShop(element);
                        }}
                      />
                    </Marker>
                    {hoveredMarker &&
                    hoveredMarker.shop_uid === element.shop_uid ? (
                      <Popup
                        longitude={element.st_x}
                        latitude={element.st_y}
                        offsetTop={-50}
                        closeButton={false}
                      >
                        <div
                          className="mapMarkerHoverDiv"
                          style={{
                            backgroundImage: `url(${
                              process.env.NODE_ENV === "production"
                                ? "https://www.locaro.in/api"
                                : "http://localhost:5000/api"
                            }/images/${element.shop_image})`,
                          }}
                        >
                          <div className="mapPopUpOverlayDiv flexColumn flexAlignCenter">
                            <h1>
                              {shop
                                ? element.shop_name.length <= 28
                                  ? element.shop_name
                                  : element.shop_name.slice(0, 28) + "..."
                                : null}
                            </h1>
                            {shop ? (
                              <div className="mapPopUpRatingDiv flex">
                                <div className="mapPopUpRating">
                                  4.5 &#9733;
                                </div>
                              </div>
                            ) : null}
                            <h2>{formatter.format(element.product_price)}</h2>
                          </div>
                        </div>
                      </Popup>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {productMainDivLoading ? <ComponentLoading /> : null}
            </ReactMaps>
          </MiddleDiv>
          <RightDiv
            catalogDetails={catalogDetails}
            shop={shop}
            productListings={productListings}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
        </div>
        {reviewsOverlay ? (
          <ProductAddReview
            catalogDetails={catalogDetails}
            setReviewsOverlay={setReviewsOverlay}
          />
        ) : null}
        <Footer />
      </React.Fragment>
    );
  }
}

export default ProductsPage;

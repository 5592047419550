import React, { useEffect, useState } from "react";
import ComponentLoading from "../../components/componentLoading";

import "./styles.css";

function LeftDiv({ catalogDetails }) {
  const [currentImage, setCurrentImage] = useState();

  useEffect(() => {
    if (Array.isArray(catalogDetails.product_images)) {
      setCurrentImage(catalogDetails.product_images[0]);
    }
  }, [catalogDetails]);

  return (
    <div id="productLeftDiv" className="flexColumn">
      <div className="productLeftDivImageDiv flex flexJustifyAndAlignCenter">
        {catalogDetails.product_images ? (
          <img
            src={`${
              process.env.NODE_ENV === "production"
                ? "https://www.locaro.in/api"
                : "http://localhost:5000/api"
            }/images/products/${currentImage ? currentImage : "null"}`}
            alt=""
          />
        ) : (
          <ComponentLoading />
        )}
      </div>
      {currentImage ? (
        <div className="productLeftDivOtherImagesDiv flexColumn">
          {catalogDetails.product_images.map((image) => {
            return (
              <div
                key={image}
                className={`flex flexJustifyAndAlignCenter ${
                  image === currentImage ? "activeImage" : null
                }`}
                onClick={() => {
                  setCurrentImage(image);
                }}
              >
                <img
                  src={`${
                    process.env.NODE_ENV === "production"
                      ? "https://www.locaro.in/api"
                      : "http://localhost:5000/api"
                  }/images/products/${image}`}
                  alt=""
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default LeftDiv;

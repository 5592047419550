import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import Nav from "../../components/navbar";
import Footer from "../../components/footer";
import { axiosUser } from "../../axiosInstance";

import "./styles.css";
import { useState } from "react";

function PromotionPage() {
  const { id } = useParams();
  const [promotion, setPromotion] = useState();

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(() => {
    axiosUser
      .get(`homepage/promotions/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setPromotion(res.data.promotion);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Nav />
      {promotion ? (
        <div className="promotionPageMainContainer">
          <div className="promotionPagePromotionBanner">
            <img
              src={`${
                process.env.NODE_ENV === "production"
                  ? "https://www.locaro.in/api"
                  : "http://localhost:5000/api"
              }/images/promotions/${promotion.promotion_image}`}
              alt=""
            />
          </div>
          <div className="promotionPageProductsContainer">
            {promotion.products.map((product) => {
              return (
                <Link
                  className="promotionPageProductCard flex card-boxshadow textDecorNone"
                  to={`/${product.is_grocery ? "grocery" : "product"}/${
                    product.catalog_uid
                  }${
                    product.is_grocery
                      ? `?shop=${product.product_uid}`
                      : `?shop=${promotion.shop_uid}`
                  }`}
                >
                  <div className="promotionPageProductCardImageDiv flex flexJustifyAndAlignCenter">
                    <img
                      src={`${
                        process.env.NODE_ENV === "production"
                          ? "https://www.locaro.in/api"
                          : "http://localhost:5000/api"
                      }/images/products/${
                        Array.isArray(product.product_image)
                          ? product.product_image[0]
                          : "null"
                      }`}
                      alt=""
                    />
                  </div>
                  <div className="promotionPageProductCardTextDiv flexColumn">
                    <h2>
                      {"> " +
                        (product.is_grocery
                          ? product.grocery_category
                          : product.product_category)}
                    </h2>
                    <h1 className="text2Lines">{product.product_name}</h1>
                    <div className="promotionPageProductCardRatingDiv flex flexAlignCenter">
                      <div
                        className="promotionPageProductCardRating"
                        style={{
                          backgroundColor:
                            product.product_rating === null
                              ? "gray"
                              : product.product_rating < 3
                              ? "#b90504"
                              : product.product_rating < 4
                              ? "orange"
                              : "green",
                        }}
                      >
                        {product.product_rating > 0
                          ? product.product_rating.toFixed(1)
                          : " -- "}{" "}
                        &#9733;
                      </div>
                      <h4>
                        {product.product_rating_count > 0
                          ? product.product_rating_count
                          : "No"}{" "}
                        Review
                        {product.rating_count > "1" ||
                        product.rating_count === "0"
                          ? "s"
                          : null}
                      </h4>
                    </div>
                    <div className="promotionPageProductCardPriceDiv flexColumn flexJustifyCenter">
                      <h3>
                        MRP:{" "}
                        <span style={{ textDecoration: "line-through" }}>
                          {formatter.format(product.product_mrp)}
                        </span>
                      </h3>
                      <h2>
                        Shop Price:{" "}
                        <span style={{ fontWeight: "700" }}>
                          {formatter.format(product.product_price)}
                        </span>
                      </h2>
                      <h4>
                        Save:{" "}
                        <span>
                          {formatter.format(
                            product.product_mrp - product.product_price
                          )}
                        </span>
                        <span
                          style={{
                            fontSize: window.outerWidth > 480 ? "13px" : "10px",
                          }}
                        >
                          {` (${Math.floor(
                            ((product.product_mrp - product.product_price) /
                              product.product_mrp) *
                              100
                          )}% OFF)`}
                        </span>
                      </h4>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      ) : null}
      <Footer />
    </>
  );
}

export default PromotionPage;

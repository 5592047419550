import React, { useRef } from "react";

import "./styles.css";

function FilterBar({ setQueryStrings, queryStrings }) {
  return (
    <div id="grocerySearchPageAutoFilterBar">
      <form className="flex" style={{ width: "100%" }}>
        <div>
          <label
            htmlFor="searchPageRadiusSelect"
            className="redBackgroundSelectLabel"
          >
            Search Radius :{" "}
          </label>
          <select
            name="radius"
            id="searchPageRadiusSelect"
            className="redBackgroundSelect"
            onChange={(e) => {
              setQueryStrings({ ...queryStrings, radius: e.target.value });
            }}
            defaultValue={
              queryStrings && queryStrings.radius ? queryStrings.radius : "10"
            }
          >
            <option value="2">2 KM</option>
            <option value="4">4 KM</option>
            <option value="6">6 KM</option>
            <option value="8">8 KM</option>
            <option value="10">10 KM</option>
          </select>
        </div>
        {/* <div
          style={{
            marginLeft: "auto",
          }}
        >
          <label
            htmlFor="grocerySearchPageChooseBySelect"
            className="redBackgroundSelectLabel"
          >
            Choose Shop :{" "}
          </label>
          <select
            name="chooseShop"
            id="grocerySearchPageChooseBySelect"
            className="redBackgroundSelect"
            onChange={(e) => {
              setQueryStrings({ ...queryStrings, by: e.target.value });
            }}
            defaultValue={
              queryStrings && queryStrings.by === "manual" ? "manual" : "auto"
            }
          >
            <option value="auto">Auto</option>
            <option value="manual">Manual</option>
          </select>
        </div> */}
      </form>
    </div>
  );
}

export default FilterBar;

import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

function Footer() {
  return (
    <React.Fragment>
      <div id="footer" className="flexColumn flexAlignCenter">
        <div id="footerLinksDiv" className="flex flexJustifyCenter">
          <div className="footerColumns flexColumn">
            <h2>About us:</h2>
            <Link to="/">What we do</Link>
            <Link to="/">Press releases</Link>
          </div>
          <div className="footerColumns flexColumn">
            <h2>Partner with us:</h2>
            <a to="https://business.locaro.in/">Sell on Locaro</a>
            <Link to="/">Careers</Link>
          </div>
          <div className="footerColumns flexColumn">
            <h2>Connect with us:</h2>
            <a href="https://twitter.com/locaro_in" target="_blank">
              Twitter
            </a>
            <a href="https://www.instagram.com/locaro.in/" target="_blank">
              Instagram
            </a>
            <a href="https://www.facebook.com/locaro.in" target="_blank">
              Facebook
            </a>
          </div>
        </div>
        <div id="footerBottom" className="flex flexJustifyAndAlignCenter">
          <h1>Locaro</h1>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Footer;

import React from "react";

import "./styles.css";

function DemoWarning({ warning, setWarning }) {
  return (
    <div
      className="warningTopDiv flex flexJustifyAndAlignCenter"
      style={{ height: warning ? "35px" : "0px" }}
    >
      Currently in demo mode
      <p
        onClick={() => {
          setWarning(false);
        }}
      >
        &times;
      </p>
    </div>
  );
}

export default DemoWarning;

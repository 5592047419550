import React from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/modules/pagination/pagination.min.css";

import "./styles.css";
import { axiosUser } from "../../axiosInstance";

SwiperCore.use([Autoplay, Pagination]);

function OfferSlider() {
  return (
    <>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        className="offerSwiper"
      >
        <SwiperSlide>
          <img
            src="https://cdn.discordapp.com/attachments/220769489833164810/909048643896627220/discount-banner-shape-special-offer-260nw-1467549638.png"
            alt=""
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://cdn.discordapp.com/attachments/220769489833164810/909048750868164638/Offers.png"
            alt=""
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default OfferSlider;

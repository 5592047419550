import React, { useState, useEffect, useContext } from "react";
import ReactMaps, { GeolocateControl, Marker } from "react-map-gl";
import { useHistory } from "react-router";
import { axiosUser } from "../../axiosInstance";
import mapboxgl from "mapbox-gl";

import { LoadingContext, UserContext } from "../../Context";
import locationPin from "./assets/locationPin.png";
import "./styles.css";
import SmallNavBar from "../../components/smallNavBar";

/* eslint-disable import/no-webpack-loader-syntax, import/no-unresolved */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
/* eslint-enable import/no-webpack-loader-syntax, import/no-unresolved */

function AddAddressPage({ isOverlay = false, setOpenAddress }) {
  const { setLoading } = useContext(LoadingContext);
  const { setUserInfo } = useContext(UserContext);
  const history = useHistory();
  const [locationText, setLocationText] = useState("");
  const [locationCoords, setLocationCoords] = useState({});
  const [viewport, setViewport] = useState({
    latitude: 19.0701,
    longitude: 72.8446,
    zoom: 10,
    width: "100%",
    height: "100%",
  });

  const geolocateControlStyle = {
    right: 10,
    top: 10,
  };

  const getLocation = () => {
    setLoading(true);
    axiosUser
      .post("map/getlocation", {
        location: {
          longitude: viewport.longitude,
          latitude: viewport.latitude,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setLocationText(res.data.location);
          setLocationCoords({
            longitude: viewport.longitude,
            latitude: viewport.latitude,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    setLoading(true);
    axiosUser
      .get("user/checkauth")
      .then((res) => {
        if (res.data.status === "success" && res.data.authorized === true) {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        history.push("/");
      });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        axiosUser
          .post("map/getlocation", {
            location: {
              longitude: position.coords.longitude,
              latitude: position.coords.latitude,
            },
          })
          .then((res) => {
            setLoading(false);
            if (res.data.status === "success") {
              setLocationText(res.data.location);
              setLocationCoords({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude,
              });
            }
          })
          .catch((err) => {
            console.log(err.message);
            setLoading(false);
          });
      },
      () => {
        console.log("Unable to retrieve your location");
        setLoading(false);
      }
    );
  }, []);

  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    const addressData = {
      userLocation: locationCoords,
      userLocationText: locationText,
      userAddress: e.target.address.value,
      userPincode: e.target.userPincode.value,
    };
    axiosUser
      .patch("/user/address/update", addressData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          if (isOverlay) {
            axiosUser
              .get("/user/info")
              .then((res) => {
                console.log(res.data);
                if (res.data.status === "success") {
                  setUserInfo({ ...res.data.user, gotUser: true });
                  setLoading(false);
                  setOpenAddress(false);
                }
              })
              .catch((err) => {
                console.log(err.message);
                setLoading(false);
              });
          } else {
            axiosUser
              .get("/user/info")
              .then((res) => {
                console.log(res.data);
                if (res.data.status === "success") {
                  setUserInfo({ ...res.data.user, gotUser: true });
                  setLoading(false);
                  history.push("/");
                }
              })
              .catch((err) => {
                console.log(err.message);
                setLoading(false);
              });
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div
      className={isOverlay ? "overlayDiv" : null}
      style={{ backgroundColor: isOverlay ? "rgba(0,0,0,0.3)" : null }}
    >
      {isOverlay ? null : <SmallNavBar />}
      <div id="addAddressMainDiv" className="flex">
        {isOverlay ? (
          <div
            className="closeButton"
            style={{
              right: window.outerWidth > 1200 ? "-40px" : "10px",
              top: window.outerWidth > 1200 ? "-10px" : "-60px",
            }}
            onClick={() => {
              setOpenAddress(false);
            }}
          >
            &times;
          </div>
        ) : null}
        <div id="addAddressFormDiv" className="flexColumn">
          <h1>New address</h1>
          <input
            type="text"
            placeholder="Location"
            id="addAddressInactiveInput"
            value={locationText}
            disabled
          />
          <form id="addAddressForm" onSubmit={submitHandler}>
            <div className="animatedInputDivs">
              <input
                type="text"
                name="address"
                id="userAddressInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userAddressInput"
                className="animatedInputLabelsSmallerLighterGray"
              >
                Flat no / Building Name / Street name
              </label>
            </div>
            <div className="animatedInputDivs">
              <input
                type="text"
                name="userPincode"
                id="userPinCodeInput"
                placeholder=" "
                autoComplete="off"
                className="animatedInputInputs"
              ></input>
              <label
                htmlFor="userPinCodeInput"
                className="animatedInputLabelsLighterGray"
              >
                Pincode
              </label>
            </div>
            <div id="addAddressButtonDiv">
              <button className="redButton" type="submit">
                Update
              </button>
              <button
                className="redButton"
                type="button"
                onClick={() => {
                  history.push("/");
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div id="addAddressMapDiv">
          <ReactMaps
            {...viewport}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            onViewportChange={(viewport) => {
              setViewport(viewport);
            }}
          >
            <GeolocateControl
              style={geolocateControlStyle}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation={true}
              auto
            />
            <Marker
              latitude={viewport.latitude}
              longitude={viewport.longitude}
              offsetLeft={-16}
              offsetTop={-50}
            >
              <img src={locationPin} alt="" width="32px" height="50px" />
            </Marker>
            <button
              onClick={getLocation}
              type="button"
              id="addAddressMapButton"
            >
              Set Location
            </button>
          </ReactMaps>
        </div>
      </div>
    </div>
  );
}

export default AddAddressPage;

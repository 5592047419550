import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useInView } from "react-intersection-observer";

import "./styles.css";
import loadingGIF from "./assets/loading.gif";
import { axiosUser } from "../../axiosInstance";

function SearchResults({ products, setProducts, maxPage }) {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const radius = query.get("radius");
  const brandQuery = queryString.parse(location.search).brand;
  const categoryQuery = queryString.parse(location.search).category;

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  // intersection observer for infinite scroll : start
  const infiniteScrollOptions = {
    threshold: 0,
    rootMargin: "0px 0px -150px 0px",
  };

  const { ref, inView, entry } = useInView(infiniteScrollOptions);

  useEffect(() => {
    if (inView === true) {
      setCurrentPage(currentPage + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (currentPage > 1 && currentPage <= maxPage) {
      axiosUser
        .get("search/products", {
          params: {
            search,
            radius,
            page: currentPage,
            brand: brandQuery,
            category: categoryQuery,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setProducts([...products, ...res.data.products]);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [currentPage]);
  // interesection observer for infinite scroll : end

  return (
    <>
      <div id="searchResultsContainer">
        {products.map((product) => {
          return (
            <div className="textDecorNone" key={product.catalog_uid}>
              <div className="searchResultCards card-boxshadow flex">
                <div
                  className="flex flexJustifyAndAlignCenter"
                  id="searchResultCardImageDiv"
                >
                  <img
                    src={`${
                      process.env.NODE_ENV === "production"
                        ? "https://www.locaro.in/api"
                        : "http://localhost:5000/api"
                    }/images/products/${
                      Array.isArray(product.product_images)
                        ? product.product_images[0]
                        : "null"
                    }`}
                    alt=""
                  />
                </div>
                <Link
                  className="searchResultCardTextDiv flexColumn textDecorNone"
                  to={`/product/${product.catalog_uid}`}
                >
                  <h2>{"> " + product.product_category}</h2>
                  <h1 className="text2Lines">{product.product_name}</h1>
                  <div className="flex" style={{ gap: "6px" }}>
                    <div className="searchResultCardRatingDiv flex flexAlignCenter">
                      <div
                        className="searchResultCardRating"
                        style={{
                          backgroundColor:
                            product.rating === null
                              ? "gray"
                              : product.rating < 3
                              ? "#b90504"
                              : product.rating < 4
                              ? "orange"
                              : "green",
                        }}
                      >
                        {product.rating > 0
                          ? product.rating.slice(0, 3)
                          : " -- "}{" "}
                        &#9733;
                      </div>
                      <h4>
                        {product.rating_count > 0 ? product.rating_count : "No"}{" "}
                        Review
                        {product.rating_count > "1" ||
                        product.rating_count === "0"
                          ? "s"
                          : null}
                      </h4>
                    </div>
                  </div>
                  <div className="searchResultCardPriceDiv flexColumn flexJustifyCenter">
                    {product.listings.length === 0 ? (
                      <div
                        style={{
                          position: "absolute",
                          left: "50%",
                          transform: "translate(-50%,0px)",
                        }}
                      >
                        Not Available In Your Area
                      </div>
                    ) : (
                      <>
                        <h3>
                          MRP:{" "}
                          <span style={{ textDecoration: "line-through" }}>
                            {formatter.format(product.product_mrp)}
                          </span>
                        </h3>
                        <h2>
                          Shop Price:{" "}
                          <span style={{ fontWeight: "700" }}>
                            {formatter.format(
                              product.listings[0].product_price
                            )}
                          </span>
                        </h2>
                        <h4>
                          Save:{" "}
                          <span>
                            {formatter.format(
                              product.product_mrp -
                                product.listings[0].product_price
                            )}
                          </span>
                          <span
                            style={{
                              fontSize:
                                window.outerWidth > 480 ? "13px" : "10px",
                            }}
                          >
                            {` (${Math.floor(
                              ((product.product_mrp -
                                product.listings[0].product_price) /
                                product.product_mrp) *
                                100
                            )}% OFF)`}
                          </span>
                        </h4>
                      </>
                    )}
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
      <div
        ref={ref}
        style={{ width: "100%", marginTop: "20px" }}
        className="flex flexJustifyAndAlignCenter"
      >
        {currentPage > maxPage ? null : (
          <img
            src={loadingGIF}
            alt=""
            style={{ width: "50px", height: "50px" }}
          />
        )}
      </div>
    </>
  );
}

export default SearchResults;

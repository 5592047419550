import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";
import Footer from "../../components/footer";
import SmallNavBar from "../../components/smallNavBar";
import { LocationContext } from "../../Context";
import GroceryImage from "./GroceryImage";
import MiddleDiv from "./MiddleDiv";
import RightDiv from "./RightDiv";
import "./styles.css";

function GroceryPage() {
  const { locationCoords } = useContext(LocationContext);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const shopQuery = query.get("shop");

  const [catalogDetails, setCatalogDetails] = useState({});
  const [shop, setShop] = useState();

  const [productMainDivLoading, setProductMainDivLoading] = useState(true);
  const [productPriceDivLoading, setProductPriceDivLoading] = useState(true);
  const [shopDetailsDivLoading, setShopDetailsDivLoading] = useState(true);

  const getProduct = () => {
    if (shopQuery === null) {
      axiosUser
        .get(`search/grocery/${id}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setCatalogDetails(res.data.catalog);
            setProductMainDivLoading(false);
            setProductPriceDivLoading(false);
            setShopDetailsDivLoading(false);
            if (res.data.shop) {
              setShop(res.data.shop);
            }
          } else {
            history.push("/error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      axiosUser
        .get(`search/grocery/${id}?product=${shopQuery}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "success") {
            setCatalogDetails(res.data.catalog);
            setProductMainDivLoading(false);
            setProductPriceDivLoading(false);
            if (res.data.shop) {
              setShop(res.data.shop);
            }
            setShopDetailsDivLoading(false);
          } else {
            history.push("/error");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  if (window.outerWidth <= 480) {
    return (
      <>
        <SmallNavBar />
        <div id="groceryPageMainContainer" className="flex">
          <MiddleDiv
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
        </div>
        <Footer />
      </>
    );
  } else if (window.outerWidth > 480 && window.outerWidth <= 1200) {
    return (
      <>
        <SmallNavBar />
        <div id="groceryPageMainContainer" className="flex">
          <MiddleDiv
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
          <RightDiv
            catalogDetails={catalogDetails}
            shop={shop}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
        </div>
        <Footer />
      </>
    );
  } else if (window.outerWidth > 1200) {
    return (
      <>
        <SmallNavBar />
        <div id="groceryPageMainContainer" className="flex">
          <GroceryImage catalogDetails={catalogDetails} />
          <MiddleDiv
            catalogDetails={catalogDetails}
            shop={shop}
            productMainDivLoading={productMainDivLoading}
          />
          <RightDiv
            catalogDetails={catalogDetails}
            shop={shop}
            shopDetailsDivLoading={shopDetailsDivLoading}
          />
        </div>
        <Footer />
      </>
    );
  }
}

export default GroceryPage;

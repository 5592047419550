import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AccountDropDown from "./AccountDropDown";

function SignedIn(props) {
  const { username, dropDown, setDropDown } = props;
  return (
    <React.Fragment>
      <div
        className="flex"
        style={{
          position: "absolute",
          right: "100px",
          height: "100%",
          gap: "30px",
        }}
      >
        <div
          id="signedInDiv"
          className="flexColumn flexJustifyCenter"
          onClick={() => {
            if (dropDown === "account") {
              setDropDown("");
            } else {
              setDropDown("account");
            }
          }}
        >
          <h3>
            {username.length <= 12 ? username : username.slice(0, 12) + "..."}
          </h3>
          <h1>Account&#x25BE;</h1>
        </div>
        <Link
          to="/orders"
          id="navOrdersDiv"
          className="textDecorNone flexColumn flexJustifyAndAlignCenter"
        >
          <h1>Orders</h1>
        </Link>
      </div>
      {dropDown === "account" ? <AccountDropDown /> : null}
    </React.Fragment>
  );
}

export default SignedIn;

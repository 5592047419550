import React, { useEffect, useState } from "react";
import SmallNavBar from "../../components/smallNavBar";
import ShopInfoCard from "./ShopInfoCard";
import RightDiv from "./RightDiv";

import "./styles.css";
import Footer from "../../components/footer";
import ShopAddReview from "../../components/shopAddReview";
import { useHistory, useParams } from "react-router";
import { axiosUser } from "../../axiosInstance";

function ShopDetailsPage() {
  const [reviewOverlay, setReviewOverlay] = useState(false);
  const [shopDetails, setShopDetails] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [imageURL, setImageURL] = useState(
    `url(${
      process.env.NODE_ENV === "production"
        ? "https://www.locaro.in/api"
        : "http://localhost:5000/api"
    }/images/shop_placeholder.jpg)`
  );

  const getShopDetails = () => {
    setInfoLoading(true);
    axiosUser
      .get(`/public/business/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setShopDetails(res.data.shop);
          setImageURL(
            `url(${
              process.env.NODE_ENV === "production"
                ? "https://www.locaro.in/api"
                : "http://localhost:5000/api"
            }/images/${res.data.shop.shop_image})`
          );
        } else {
          history.push("/error");
        }
        setInfoLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setInfoLoading(false);
      });
  };

  useEffect(() => {
    getShopDetails();
  }, []);

  if (window.outerWidth <= 780) {
    return (
      <>
        <SmallNavBar />
        <div id="shopDetailsMainContainer" className="flexColumn">
          <ShopInfoCard
            imageURL={imageURL}
            shopDetails={shopDetails}
            infoLoading={infoLoading}
          />
          <RightDiv setReviewOverlay={setReviewOverlay} />
        </div>
        {reviewOverlay ? (
          <ShopAddReview
            shopDetails={shopDetails}
            setReviewOverlay={setReviewOverlay}
          />
        ) : null}
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <SmallNavBar />
        <div id="shopDetailsMainContainer" className="flex">
          <ShopInfoCard
            imageURL={imageURL}
            shopDetails={shopDetails}
            infoLoading={infoLoading}
          />
          <RightDiv setReviewOverlay={setReviewOverlay} />
        </div>
        <div className="shopDetailsReviewsMainContainer flex"></div>
        {reviewOverlay ? (
          <ShopAddReview
            setReviewOverlay={setReviewOverlay}
            shopDetails={shopDetails}
          />
        ) : null}
        <Footer />
      </>
    );
  }
}

export default ShopDetailsPage;

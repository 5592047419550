import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { axiosUser } from "../../axiosInstance";
import ComponentLoading from "../../components/componentLoading";
import { LoadingContext, UserContext } from "../../Context";

function Reviews({ setReviewOverlay }) {
  const history = useHistory();
  const { id } = useParams();
  const [userReview, setUserReview] = useState();
  const [reviewList, setReviewList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [componentLoading, setComponentLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setComponentLoading(true);
    axiosUser
      .get(`/public/business/${id}/reviews`, { params: { page: currentPage } })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setReviewList(res.data.reviews);
          axiosUser
            .get(`/user/shops/review`, { params: { id } })
            .then((res) => {
              console.log(res.data);
              if (res.data.status === "success") {
                setUserReview(res.data.userReview);
              }
              setComponentLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setComponentLoading(false);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setComponentLoading(false);
      });
  }, [currentPage]);

  useEffect(() => {
    if (userReview && reviewList[0]) {
      setReviewList(
        reviewList.filter(
          (element) => element.shop_review_uid !== userReview.shop_review_uid
        )
      );
    }
  }, [userReview]);

  const deleteHandler = () => {
    setLoading(true);
    axiosUser
      .delete(`/user/shops/review`, { params: { id } })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          axiosUser
            .get(`/user/shops/review`, { params: { id } })
            .then((res) => {
              setLoading(false);
              console.log(res.data);
              if (res.data.status === "success") {
                setUserReview(res.data.userReview);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (reviewList.length % 20 > 0) {
      setMaxPage(Math.floor(reviewList.length / 20) + 1);
    } else {
      setMaxPage(Math.floor(reviewList.length / 20));
    }
  }, [reviewList]);

  return (
    <div id="shopDetailsReviewContainer" className="flexColumn">
      {userReview && userReview !== null ? (
        <>
          <div className="flex flexAlignCenter">
            <h1>Your Review</h1>
            <div
              className="redButton flex flexJustifyAndAlignCenter"
              style={{
                height: "30px",
                padding: "0 10px",
                margin: "7px 15px 0 auto",
              }}
              onClick={deleteHandler}
            >
              Delete
            </div>
          </div>
          <div className="shopReviewCard flexColumn">
            <div className="flex">
              <div
                className="productMiddleDivRating"
                style={{
                  backgroundColor:
                    userReview.review_rating <= 2
                      ? "#b90504"
                      : userReview.review_rating <= 3
                      ? "orange"
                      : userReview.review_rating < 4
                      ? "yellow"
                      : "green",
                }}
              >
                {userReview.review_rating}.0 &#9733;
              </div>
              {userReview.review_text.length > 0 ? (
                <p>{userReview.review_text}</p>
              ) : (
                <h2 style={{ marginTop: "5px" }}>
                  ~ {userReview.user_full_name} <span>(Certified Buyer)</span>
                </h2>
              )}
            </div>
            {userReview.review_text.length > 0 ? (
              <h2>
                ~ {userReview.user_full_name} <span>(Certified Buyer)</span>
              </h2>
            ) : null}
          </div>
        </>
      ) : (
        <div
          className="shopReviewCard flex flexJustifyAndAlignCenter"
          style={{ height: "80px" }}
        >
          <div
            className="redButton flex flexJustifyAndAlignCenter"
            onClick={() => {
              if (userInfo.gotUser) {
                setReviewOverlay(true);
              } else {
                history.push("/user/login");
              }
            }}
          >
            Write A Review
          </div>
        </div>
      )}
      {reviewList[0] ? (
        <>
          <h1>Reviews</h1>
          {reviewList.map((element) => {
            return (
              <div
                className="shopReviewCard flexColumn"
                key={element.shop_review_uid}
              >
                <div className="flex">
                  <div
                    className="productMiddleDivRating"
                    style={{
                      backgroundColor:
                        element.review_rating < 3
                          ? "#b90504"
                          : element.review_rating < 4
                          ? "orange"
                          : "green",
                    }}
                  >
                    {element.review_rating}.0 &#9733;
                  </div>
                  <p>{element.review_text}</p>
                </div>
                <h2>
                  ~ {element.user_full_name} <span>(Certified Buyer)</span>
                </h2>
              </div>
            );
          })}
        </>
      ) : null}
      {currentPage < maxPage ? (
        <div
          style={{
            textAlign: "center",
            color: "dimgray",
            cursor: "pointer",
          }}
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}
        >
          Show more
        </div>
      ) : null}
      {componentLoading ? <ComponentLoading minHeight="100%" /> : null}
    </div>
  );
}

export default Reviews;

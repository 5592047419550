import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axiosUser } from "../../axiosInstance";
import { LoadingContext, UserContext } from "../../Context";
import AddAddressPage from "../../PortalPages/AddAddressPage";

import "./styles.css";

function Payment({ selectedShop, setOpenPayment }) {
  const { setLoading } = useContext(LoadingContext);
  const history = useHistory();
  const { userInfo } = useContext(UserContext);
  const [total, setTotal] = useState(0);
  const [openAddress, setOpenAddress] = useState(false);
  const [loadedRazorpay, setLoadedRazorpay] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    let subtotal = 0;
    for (let product of selectedShop.products) {
      subtotal += product.product_price * product.quantity;
    }
    setTotal(subtotal);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      setLoadedRazorpay(true);
      console.log("loaded razorpay");
    };
    script.onerror = () => {
      setOpenPayment(false);
      alert("Something went wrong, please check your internet");
    };
    document.body.appendChild(script);
  }, []);

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const paymentHandler = async () => {
    setLoading(true);
    axiosUser
      .post("/payment/create", { shops: [selectedShop] })
      .then((res) => {
        setLoading(false);
        if (res.data.status === "success") {
          const options = {
            key: "rzp_test_OMEFhq33QJYTBL",
            currency: res.data.currency,
            amount: res.data.amount,
            order_id: res.data.id,
            name: "Locaro",
            description: "Thank you for ordering!",
            // image: "http://localhost:1337/logo.svg",
            handler: function (response) {
              setLoading(true);
              axiosUser
                .post("/payment/confirm", { response })
                .then((res) => {
                  setLoading(false);
                  if (res.data.status === "success") {
                    setOpenPayment(false);
                    history.push({
                      pathname: "/orders",
                      state: {
                        status: "success",
                        message: "Your order has been placed",
                      },
                    });
                    console.log("SUCCESS", res.data);
                  } else {
                    setError(
                      "Something went wrong. Any payments made will be refunded within 5-7 days."
                    );
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                  setError(
                    "Something went wrong. Any payments made will be refunded within 5-7 days."
                  );
                });
            },
            prefill: {
              name: userInfo.user_full_name,
              email: userInfo.user_email,
              phone_number: userInfo.user_phone_number,
            },
            theme: {
              color: "#b90504",
            },
          };
          const paymentObject = new window.Razorpay(options);

          paymentObject.on("payment.failed", function (response) {
            setLoading(false);
            setError("Failed to make payment!");
          });

          paymentObject.open();
        } else {
          setError("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError("Something went wrong!");
      });
  };

  return (
    <div
      className={`overlayDiv flexColumn ${
        window.outerWidth <= 780
          ? "flexAlignCenter"
          : "flexJustifyAndAlignCenter"
      }`}
      style={{ overflowY: window.outerWidth <= 780 ? "scroll" : null }}
    >
      {error ? (
        <div
          className="flashCardError"
          style={{
            top: "80px",
            maxWidth: "600px",
            width: "calc(100% - 20px)",
            margin: "20px 0px",
          }}
        >
          {error}
        </div>
      ) : null}
      <div className="paymentContainer flex">
        <div
          className="closeButton"
          style={{
            right: window.outerWidth > 1200 ? "-40px" : "10px",
            top: window.outerWidth > 1200 ? "-10px" : "-60px",
          }}
          onClick={() => {
            setOpenPayment(false);
          }}
        >
          &times;
        </div>
        <div className="paymentLeftDiv">
          <div className="paymentAddressDiv flexColumn flexJustifyAndAlignCenter">
            {userInfo.user_address !== null ? (
              <>
                <h1>Default address:</h1>
                <p>
                  <span>{userInfo.user_full_name}</span>,{" "}
                  {userInfo.user_address}, <span>{userInfo.user_pincode}</span>
                </p>
                <button
                  className="redButton"
                  onClick={() => {
                    setOpenAddress(true);
                  }}
                >
                  Edit
                </button>
              </>
            ) : (
              <button
                className="redButton"
                style={{ margin: "0" }}
                onClick={() => {
                  setOpenAddress(true);
                }}
              >
                Add An Address
              </button>
            )}
          </div>
          {window.outerWidth > 480 ? (
            <div className="paymentProductsDiv">
              {selectedShop.products.map((product) => {
                return (
                  <div
                    className="paymentProductsCard flex"
                    key={product.product_uid}
                  >
                    <div className="paymentProductTextDiv">
                      <h2>{product.product_name}</h2>
                      <p>{selectedShop.shop_name}</p>
                    </div>
                    <div className="paymentProductPriceDiv">
                      <p>{formatter.format(product.product_price)}</p>
                      <p>&times; {product.quantity}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="paymentRightDiv flexColumn flexJustifyAndAlignCenter">
          <div className="paymentOrderingForDiv">
            <h1 className="paymentRightDivHeaders">Ordering for</h1>
            <p>
              <span>{userInfo.user_full_name}</span>,{" "}
              {userInfo.user_phone_number}
            </p>
          </div>
          <div className="paymentRightDivSummary flexColumn">
            <h1
              className="paymentRightDivHeaders"
              style={{ margin: "5px 0 5px 10px" }}
            >
              Summary
            </h1>
            <div className="flex" style={{ padding: "0px 10px" }}>
              <p>Subtotal:</p>
              <p style={{ marginLeft: "auto" }}>{formatter.format(total)}</p>
            </div>
            <div className="flex" style={{ padding: "0px 10px" }}>
              <p>Delivery charges:</p>
              <p style={{ marginLeft: "auto" }}>{formatter.format(60)}</p>
            </div>
            <div className="flex" style={{ padding: "0px 10px" }}>
              <p>Taxes:</p>
              <p style={{ marginLeft: "auto" }}>{formatter.format(200)}</p>
            </div>
            <div className="paymentTotalDiv flex flexJustifyAndAlignCenter">
              Total: {formatter.format(total + 260)}
            </div>
          </div>
          <button
            className="redButton"
            style={{ marginTop: "10px" }}
            onClick={paymentHandler}
          >
            Proceed to payment
          </button>
          {userInfo.user_address === null ? (
            <div className="paymentRightDivOverlay flex flexJustifyAndAlignCenter">
              Add an address to continue
            </div>
          ) : null}
        </div>
      </div>
      {openAddress ? (
        <AddAddressPage isOverlay={true} setOpenAddress={setOpenAddress} />
      ) : null}
    </div>
  );
}

export default Payment;

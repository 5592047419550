import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.css";
import locationSVG from "./assets/location.svg";

import { axiosUser } from "../../axiosInstance";
import { LoadingContext } from "../../Context.js";

function RegionDropDown(props) {
  const { currentLocation, showError, userInfo, dropDown, setDropDown } = props;
  const [suggestions, setSuggestions] = useState([]);
  const mobileOverlay = useRef();
  const mobileRegionDropDown = useRef();

  const getSuggestions = (name) => {
    if (name.length > 0) {
      axiosUser
        .get("map/search", { params: { query: name } })
        .then((res) => {
          if (res.data.status === "success") {
            console.log(res.data);
            setSuggestions(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const locationButtonHandler = () => {
    axiosUser
      .post("map/setlocation/force", { location: currentLocation })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const suggestionClickHandler = (index) => {
    const geoData = {
      longitude: suggestions[index].center[0],
      latitude: suggestions[index].center[1],
    };
    console.log(geoData);
    axiosUser
      .post("map/setlocation/force", { location: geoData })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  if (window.outerWidth <= 780) {
    return (
      <div
        className="overlayDiv"
        style={{ touchAction: "none", animationName: "overlayFadeIn" }}
        ref={mobileOverlay}
      >
        <div
          style={{ height: "100%", width: "100%" }}
          onClick={() => {
            mobileOverlay.current.style.animationName = "overlayFadeOut";
            mobileRegionDropDown.current.style.animationName = "regionPopDown";
            setTimeout(() => {
              setDropDown("");
            }, 480);
          }}
        ></div>
        <div
          id="regionDropDownDiv"
          className="flexColumn flexAlignCenter"
          ref={mobileRegionDropDown}
        >
          <h1>Choose Your Delivery Location</h1>
          {!userInfo.gotUser ? <NotSignedInRegionDropDownDiv /> : null}
          {userInfo.gotUser &&
          userInfo.user_address === null &&
          userInfo.st_x === null &&
          userInfo.st_y === null &&
          userInfo.user_pincode === null ? (
            <SignedInWithoutAddressRegionDropDownDiv />
          ) : null}
          {userInfo.gotUser &&
          userInfo.user_address !== null &&
          userInfo.st_x !== null &&
          userInfo.st_y !== null &&
          userInfo.user_pincode !== null ? (
            <SignedInWithAddressRegionDropDownDiv userInfo={userInfo} />
          ) : null}
          <h2>OR</h2>
          <div id="regionSearchBoxParentDiv" className="flexColumn">
            <div id="regionSearchBoxDiv">
              <input
                type="text"
                name="search"
                onChange={(event) => {
                  getSuggestions(event.target.value);
                }}
                autoComplete="off"
                spellCheck="false"
                style={showError ? { width: "85%" } : null}
              />
              {!showError ? (
                <button
                  className="redButton flex flexJustifyAndAlignCenter"
                  onClick={locationButtonHandler}
                >
                  Use my location
                  <img src={locationSVG} alt="" />
                </button>
              ) : null}
            </div>
            {suggestions.map((suggestion) => {
              return (
                <div
                  key={suggestion.id}
                  className="regionSearchSuggestionDiv flex flexAlignCenter"
                  onClick={() => {
                    suggestionClickHandler(suggestions.indexOf(suggestion));
                  }}
                >
                  <h3>{suggestion.place_name}</h3>
                </div>
              );
            })}
          </div>
          {showError ? (
            <div
              id="regionDropDownErrorDiv"
              className="flex flexJustifyAndAlignCenter"
            >
              <h4>
                Unable to get your current location. Please allow access to
                location from your settings and refresh.
              </h4>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div
        id="navDropDownOverlay"
        onClick={() => {
          setDropDown("");
        }}
        style={{ touchAction: "none" }}
      ></div>
      <div id="regionDropDownDiv" className="flexColumn flexAlignCenter">
        <h1>Choose Your Delivery Location</h1>
        {!userInfo.gotUser ? <NotSignedInRegionDropDownDiv /> : null}
        {userInfo.gotUser &&
        userInfo.user_address === null &&
        userInfo.st_x === null &&
        userInfo.st_y === null &&
        userInfo.user_pincode === null ? (
          <SignedInWithoutAddressRegionDropDownDiv />
        ) : null}
        {userInfo.gotUser &&
        userInfo.user_address !== null &&
        userInfo.st_x !== null &&
        userInfo.st_y !== null &&
        userInfo.user_pincode !== null ? (
          <SignedInWithAddressRegionDropDownDiv userInfo={userInfo} />
        ) : null}
        <h2>OR</h2>
        <div id="regionSearchBoxParentDiv" className="flexColumn">
          <div id="regionSearchBoxDiv">
            <input
              type="text"
              name="search"
              onChange={(event) => {
                getSuggestions(event.target.value);
              }}
              autoComplete="off"
              spellCheck="false"
            />
            {!showError ? (
              <button
                className="redButton flex flexJustifyAndAlignCenter"
                onClick={locationButtonHandler}
              >
                Use my location
                <img src={locationSVG} alt="" />
              </button>
            ) : null}
          </div>
          {suggestions.map((suggestion) => {
            return (
              <div
                key={suggestion.id}
                className="regionSearchSuggestionDiv flex flexAlignCenter"
                onClick={() => {
                  suggestionClickHandler(suggestions.indexOf(suggestion));
                }}
              >
                <h3>{suggestion.place_name}</h3>
              </div>
            );
          })}
        </div>
        {showError ? (
          <div
            id="regionDropDownErrorDiv"
            className="flex flexJustifyAndAlignCenter"
          >
            <h4>
              Unable to get your current location. Please allow access to
              location from your settings and refresh.
            </h4>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

function NotSignedInRegionDropDownDiv() {
  return (
    <div
      id="notSignedInRegionDropDownDiv2"
      className="flexColumn flexAlignCenter"
    >
      <h1>Sign in to use your default address</h1>
      <Link to="/user/login">
        <button className="redButton">Sign In</button>
      </Link>
    </div>
  );
}

function SignedInWithoutAddressRegionDropDownDiv() {
  return (
    <div
      id="signedInWithoutAddressRegionDropDownDiv2"
      className="flexColumn flexAlignCenter"
    >
      <h1>Set up a default location</h1>
      <Link to="/user/address/add">
        <button className="redButton" style={{ margin: "15px 0px" }}>
          Add Address
        </button>
      </Link>
    </div>
  );
}

function SignedInWithAddressRegionDropDownDiv(props) {
  const { userInfo } = props;
  const { setLoading } = useContext(LoadingContext);
  const useAddressButtonHandler = () => {
    setLoading(true);
    axiosUser
      .post("map/setlocation/force", {
        location: { longitude: userInfo.st_x, latitude: userInfo.st_y },
      })
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };
  return (
    <div
      id="signedInWithAddressRegionDropDownDiv2"
      className="flexColumn flexAlignStart"
    >
      <h1>Default address:</h1>
      <h2>
        <span style={{ fontWeight: "500" }}>{userInfo.user_full_name},</span>{" "}
        {userInfo.user_address}
      </h2>
      <h2 style={{ fontWeight: "500", marginBottom: "55px" }}>
        {userInfo.user_pincode}
      </h2>
      <button
        className="redButton"
        onClick={useAddressButtonHandler}
        style={{
          height: "35px",
          padding: "0px 10px",
          position: "absolute",
          bottom: "10px",
          left: "15px",
        }}
      >
        Use this address
      </button>
      <Link to="/user/address/add">
        <button
          className="redButton"
          style={{
            height: "35px",
            padding: "0px 20px",
            position: "absolute",
            bottom: "10px",
            right: "15px",
          }}
        >
          Edit
        </button>
      </Link>
    </div>
  );
}

export default RegionDropDown;

import React, { useState, useEffect, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import queryString from "query-string";

import ManualMode from "./ManualMode";
import FilterBar from "./FilterBar";
import { axiosUser } from "../../axiosInstance";
import { LocationContext } from "../../Context";
import { SearchFilterBox } from "../../components/searchFilterBox";
import ComponentLoading from "../../components/componentLoading";

function GroceryManualPage({ queryStrings, setQueryStrings, setMobilePopUp }) {
  const { locationCoords } = useContext(LocationContext);
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxPage, setMaxPage] = useState();
  const query = new URLSearchParams(useLocation().search);
  const search = query.get("search");
  const radius = query.get("radius");
  const brandQuery = queryString.parse(location.search).brand;
  const categoryQuery = queryString.parse(location.search).category;

  const getProducts = (page) => {
    setLoading(true);
    axiosUser
      .get("search/grocery/manual", {
        params: {
          search,
          radius,
          page,
          brand: brandQuery,
          category: categoryQuery,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setProducts(res.data.products);
          console.log("products", res.data.products);
          setLoading(false);
          if (res.data.count % 40 > 0) {
            setMaxPage(Math.floor(res.data.count / 40) + 1);
          } else {
            setMaxPage(Math.floor(res.data.count / 40));
          }
        } else {
          console.log(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProducts(1);
  }, [location, locationCoords]);

  if (!products[0] && !loading) {
    if (window.outerWidth <= 780) {
      return (
        <>
          <div
            id="grocerySearchPageMainContainer"
            className="flexColumn flexAlignCenter"
            style={{ gap: "40px" }}
          >
            <FilterBar
              queryStrings={queryStrings}
              setQueryStrings={setQueryStrings}
            />
            <div
              id="productsNoResultsDiv"
              className=" flexColumn flexJustifyAndAlignCenter"
              style={{ textAlign: "center", width: "100%", marginTop: "40px" }}
            >
              <h1>Sorry, no listings available for manual.</h1>
              <p>
                Check for spelling errors or try searching for something else
              </p>
              <Link
                to="/"
                className="redButton flex flexJustifyAndAlignCenter textDecorNone"
              >
                Check Grocery Categories
              </Link>
            </div>
            <SearchFilterBox page="grocery" setMobilePopUp={setMobilePopUp} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            id="grocerySearchPageMainContainer"
            className="flex flexJustifyCenter"
          >
            <SearchFilterBox page="grocery" />
            <div
              className="flexColumn flexAlignCenter"
              style={{ width: "80%", gap: "40px" }}
            >
              <FilterBar
                queryStrings={queryStrings}
                setQueryStrings={setQueryStrings}
              />
              <div
                id="productsNoResultsDiv"
                className=" flexColumn flexJustifyAndAlignCenter"
                style={{
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <h1>Sorry, no listings available for manual.</h1>
                <p>
                  Check for spelling errors or try searching for something else
                </p>
                <Link
                  to="/"
                  className="redButton flex flexJustifyAndAlignCenter textDecorNone"
                >
                  Check Grocery Categories
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    }
  } else {
    if (window.outerWidth <= 780) {
      return (
        <>
          <div
            id="grocerySearchPageMainContainer"
            className="flexColumn flexAlignCenter"
          >
            <FilterBar
              setQueryStrings={setQueryStrings}
              queryStrings={queryStrings}
            />
            <ManualMode
              products={products}
              getProducts={getProducts}
              setProducts={setProducts}
              maxPage={maxPage}
            />
            <SearchFilterBox setMobilePopUp={setMobilePopUp} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            id="grocerySearchPageMainContainer"
            className="flex flexJustifyCenter"
          >
            <SearchFilterBox page="grocery" />
            <div id="grocerySearchPageRightContainer">
              <FilterBar
                queryStrings={queryStrings}
                setQueryStrings={setQueryStrings}
              />
              <ManualMode
                products={products}
                getProducts={getProducts}
                setProducts={setProducts}
                maxPage={maxPage}
              />
            </div>
          </div>
          {loading ? <ComponentLoading position="fixed" /> : null}
        </>
      );
    }
  }
}

export default GroceryManualPage;

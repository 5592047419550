import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { axiosUser } from "../../axiosInstance";

import "./styles.css";
import searchButton from "./assets/searchButton.png";

function Search({
  dropDown,
  setDropDown,
  mainSearchInput,
  searchModeProp = "products",
}) {
  const searchRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const [searchMode, setSearchMode] = useState(searchModeProp);
  const productsTab = useRef();
  const groceriesTab = useRef();
  const [suggestions, setSuggestions] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();
    const query = new URLSearchParams(location.search);
    const radius = query.get("radius");
    const search = searchRef.current.value;
    setDropDown("");
    history.push(
      `/search/${searchMode}?search=${search}${
        radius === null ? "" : `&radius=${radius}`
      }`
    );
    window.location.reload();
  };

  useEffect(() => {
    if (
      searchMode === "products" &&
      productsTab.current &&
      groceriesTab.current
    ) {
      productsTab.current.classList.add("navBarSearchTabActive");
      groceriesTab.current.classList.remove("navBarSearchTabActive");
    } else if (
      searchMode === "grocery" &&
      productsTab.current &&
      groceriesTab.current
    ) {
      productsTab.current.classList.remove("navBarSearchTabActive");
      groceriesTab.current.classList.add("navBarSearchTabActive");
    }
  }, [searchMode, productsTab, groceriesTab, dropDown]);

  const searchOnChange = (search) => {
    if (search[0]) {
      axiosUser
        .get("search/suggestions", { params: { search, mode: searchMode } })
        .then((res) => {
          console.log(res.data);
          if (res.data.products[0]) {
            setSuggestions(res.data.products);
          } else if (!res.data.products[0] && !suggestions[0]) {
            setSuggestions([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    setSuggestions([]);
    searchOnChange(searchRef.current.value);
  }, [searchMode]);

  return (
    <>
      {dropDown === "search" ? (
        <div
          className="navBarSearchBarOverlay"
          onClick={() => {
            setDropDown("");
          }}
        ></div>
      ) : null}

      <div className="navBarSearchBarDiv">
        <form onSubmit={submitHandler} className="flex flexAlignCenter">
          <input
            type="text"
            id="searchBar"
            name="mainSearchInput"
            autoComplete="off"
            placeholder="Search for products"
            onClick={() => {
              setDropDown("search");
            }}
            onChange={(e) => {
              searchOnChange(e.target.value);
            }}
            ref={searchRef}
          ></input>
          <button id="searchButton">
            <img
              src={searchButton}
              alt=""
              width="28px"
              height="28px"
              style={{ marginTop: "2px" }}
              ref={mainSearchInput}
            />
          </button>
        </form>
        {dropDown === "search" ? (
          <div
            className="navBarSearchDropDown flexColumn"
            style={{ marginTop: "21px" }}
          >
            <div className="navBarSearchTabDiv flex">
              <div
                ref={productsTab}
                className="navBarSearchTabs flex flexJustifyAndAlignCenter"
                onClick={() => {
                  setSearchMode("products");
                }}
              >
                Products
              </div>
              <div
                ref={groceriesTab}
                className="navBarSearchTabs flex flexJustifyAndAlignCenter"
                onClick={() => {
                  setSearchMode("grocery");
                }}
              >
                Groceries
              </div>
            </div>
            {suggestions[0] ? (
              <div className="navBarSearchSuggestionsDiv">
                {suggestions.map((suggestion) => {
                  return (
                    <Link
                      to={`/${
                        searchMode === "products" ? "product" : "grocery"
                      }/${suggestion.catalog_uid}`}
                      className="navBarSearchSuggestionsCard flex flexAlignCenter textDecorNone"
                      key={suggestion.catalog_uid}
                    >
                      <p className="text1Line">{suggestion.product_name}</p>
                    </Link>
                  );
                })}
              </div>
            ) : null}
            {!suggestions[0] && searchRef.current.value[0] ? (
              <div className="flex flexJustifyAndAlignCenter navBarSearchNoResultsDiv">
                No results found
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Search;

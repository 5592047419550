import React, { useState, useEffect, Children } from "react";

import ComponentLoading from "../../components/componentLoading";
import GroceryImage from "./GroceryImage";
import RightDiv from "./RightDiv";

function MiddleDiv(props) {
  const { catalogDetails, shop, productMainDivLoading, shopDetailsDivLoading } =
    props;

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  if (window.outerWidth <= 480) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {/* debating over ratings on groceries */}
          {/* {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div className="productMiddleDivRating">4.5 &#9733;</div>
              <h1>800 Reviews</h1>
            </div>
          )} */}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ margin: "10px 15px" }}>
              Grocery
            </div>
          ) : null}
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        <GroceryImage catalogDetails={catalogDetails} />
        <RightDiv
          catalogDetails={catalogDetails}
          shop={shop}
          shopDetailsDivLoading={shopDetailsDivLoading}
        />
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  } else if (window.outerWidth > 480 && window.outerWidth <= 1200) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {/* debating over ratings on groceries */}
          {/* {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div className="productMiddleDivRating">4.5 &#9733;</div>
              <h1>800 Reviews</h1>
            </div>
          )} */}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ margin: "0px 20px" }}>
              Grocery
            </div>
          ) : null}
          <div className="productMiddleDivSectionPriceDiv">
            <h3 style={shop ? null : { fontSize: "22px" }}>
              MRP:{" "}
              <span style={shop ? null : { textDecoration: "none" }}>
                {catalogDetails.product_mrp
                  ? formatter.format(catalogDetails.product_mrp)
                  : null}
              </span>
            </h3>
            {shop ? (
              <>
                {" "}
                <h2 className="productMiddleDivSectionPrice">
                  Shop Price:{" "}
                  <span>
                    {shop
                      ? formatter.format(shop.product_price)
                      : "Unavailable in your location"}
                  </span>
                </h2>
                <h4>
                  Save:{" "}
                  <span>
                    {shop
                      ? formatter.format(
                          catalogDetails.product_mrp - shop.product_price
                        )
                      : null}
                  </span>
                </h4>
              </>
            ) : null}
          </div>
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        <GroceryImage catalogDetails={catalogDetails} />
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  } else if (window.outerWidth > 1200) {
    return (
      <div id="productsMiddleDiv">
        <div className="productMiddleDivSections">
          <h1 className="productMiddleDivProductName">
            {catalogDetails.product_name ? catalogDetails.product_name : null}
          </h1>
          {/* debating over ratings on groceries */}
          {/* {productMainDivLoading ? null : (
            <div className="productMiddleDivRatingDiv">
              <div className="productMiddleDivRating">4.5 &#9733;</div>
              <h1>800 Reviews</h1>
            </div>
          )} */}
          {catalogDetails.is_grocery ? (
            <div className="groceryTag" style={{ marginLeft: "20px" }}>
              Grocery
            </div>
          ) : null}
          <div className="productMiddleDivSectionPriceDiv">
            <h3 style={shop ? null : { fontSize: "22px" }}>
              MRP:{" "}
              <span style={shop ? null : { textDecoration: "none" }}>
                {catalogDetails.product_mrp
                  ? formatter.format(catalogDetails.product_mrp)
                  : null}
              </span>
            </h3>
            {shop ? (
              <>
                {" "}
                <h2 className="productMiddleDivSectionPrice">
                  Shop Price:{" "}
                  <span>
                    {shop
                      ? formatter.format(shop.product_price)
                      : "Unavailable in your location"}
                  </span>
                </h2>
                <h4>
                  Save:{" "}
                  <span>
                    {shop
                      ? formatter.format(
                          catalogDetails.product_mrp - shop.product_price
                        )
                      : null}
                  </span>
                  <span
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {shop
                      ? ` (${Math.floor(
                          ((catalogDetails.product_mrp - shop.product_price) /
                            catalogDetails.product_mrp) *
                            100
                        )}% OFF)`
                      : null}
                  </span>
                </h4>
              </>
            ) : null}
          </div>
          {productMainDivLoading ? <ComponentLoading /> : null}
        </div>
        {catalogDetails.product_specs &&
        catalogDetails.product_specs !== null ? (
          <div className="productMiddleDivSections">
            <h1 className="productMiddleDivSectionHeader">Specifications</h1>
            {catalogDetails.product_specs.map((category) => {
              return (
                <div
                  className="productMiddleDivSpecsCategoryDiv"
                  key={category.category}
                >
                  <h1 className="productMiddleDivSpecsHeader">
                    {category.category}
                  </h1>
                  {category.fields.map((fields) => {
                    const key = Object.keys(fields);
                    return (
                      <div className="productMiddleDivSpecsFields" key={key[0]}>
                        <h1>{key[0]}</h1>
                        <h2>{fields[key]}</h2>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default MiddleDiv;
